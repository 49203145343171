import React, { useState } from 'react';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Drawer } from '@/components/Drawer/Drawer';
import { useTranslation } from 'react-i18next';
import { AngleBackIcon } from '@/components/UI/Icons/Icons';
import { Checkbox } from '@/components/UI/Checkbox/Checkbox';
import { useQuery } from '@apollo/client';
import { GET_REGIONS } from '@/api/queries/regions.queries';
import { GetRegionsResponse, Region } from '@/api/types/regions.types';

interface CitiesDrawerProps {
  isOpen: boolean;
  close: () => void;
  onSelect: (value: Region) => void;
  selected: string;
}

export const CitiesDrawer: React.FC<CitiesDrawerProps> = ({
  isOpen,
  close,
  onSelect,
  selected,
}) => {
  const { t } = useTranslation();
  const [query, setQuery] = useState('');

  const { data: regions } = useQuery<GetRegionsResponse>(GET_REGIONS);

  const cities = regions?.regions.filter(c =>
    c.name.toLowerCase().includes(query.toLowerCase())
  );

  function onOptionClick(value: Region) {
    onSelect(value);
    close();
  }

  return (
    <Drawer
      isOpen={isOpen}
      title={t('common.city')}
      left={{ icon: <AngleBackIcon />, onClick: close }}
      onSearch={setQuery}
    >
      {!!cities?.length && (
        <DrawerItems>
          {cities.map(city => (
            <Checkbox
              onChangeHook={() => onOptionClick(city)}
              key={city.name}
              value={city.name === selected}
              label={city.name}
              appearance="radioCheckIcon"
              size="sm"
            />
          ))}
        </DrawerItems>
      )}
    </Drawer>
  );
};
