import { PropertyOption } from '@/api/types/categories.types';

export function createNewUrlWithPropertyOption(
  newPropertyOption: PropertyOption
) {
  const url = new URL(window.location.href);

  const params = new URLSearchParams(url.search);

  const { legacyResourceId, propertyLegacyId } = newPropertyOption;
  params.set(`p[${propertyLegacyId}]`, legacyResourceId.toString());

  url.search = params.toString();

  return url.toString();
}
