import { PropertyOption } from '@/api/types/categories.types';
import { Collection } from '@/components/FiltersDrawer/FiltersDrawer.types';

export function groupByPropertyLegacyId(options: PropertyOption[]) {
  const grouped = options.reduce<{ [key: string]: PropertyOption[] }>(
    (acc, option) => {
      const key = option.propertyLegacyId;

      if (!acc[key]) {
        acc[key] = [];
      }

      const exists = acc[key].some(
        existingOption =>
          existingOption.legacyResourceId === option.legacyResourceId
      );

      if (!exists) {
        acc[key].push(option);
      }

      return acc;
    },
    {}
  );

  return Object.values(grouped);
}

export function isCollectionCleared(collection: Collection) {
  return !Object.keys(collection).length;
}
