import { useEffect } from 'react';
import { sleep } from '@/utils/helpers';
import { MODAL_ANIMATION_DURATION } from '@/styles/constants';

export const useBodyOverflow = (isOpen?: boolean) => {
  useEffect(() => {
    async function handle() {
      await sleep(MODAL_ANIMATION_DURATION);
      window.scroll({ top: 0 });
      await sleep(10);
      document.documentElement.style.overflow = isOpen ? 'hidden' : 'auto';
      document.documentElement.style.position = isOpen ? 'fixed' : '';
      document.documentElement.style.width = isOpen ? '100%' : '';
    }

    handle();
  }, [isOpen]);

  return null;
};
