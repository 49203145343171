import { PageInfo } from '@/api/types/pageInfo.types';
import { Ad, AssetUrl, MutationResponse } from '@/api/types/common.types';
import { ShopInAdDetails } from '@/api/types/shops.types';
import { AdStatus } from '@/types';
import { AdService } from '@/api/types/services.types';

export enum ServiceName {
  Vip = 'VIP',
  Premium = 'PREMIUM',
  Latest = 'LATEST',
}

export enum AdOrder {
  DateDesc = 'DATE_DESC', // by default
  PriceAsc = 'PRICE_ASC',
  PriceDesc = 'PRICE_DESC',
  Random = 'RANDOM',
}

export enum ContactType {
  CallsAndMessages = 'calls_and_messages',
  OnlyCalls = 'only_calls',
  OnlyMessages = 'only_messages',
}

export type AdContact = {
  id: string;
  name: string;
  logo: AssetUrl | null;
  phones: string[];
  type: ContactType;
};

export type AdProperty = {
  name: string;
  value: string;
  link: string | null;
};

export type ReportReason = {
  id: string;
  name: string;
};

export type RejectReason = {
  id: string;
  name: string;
  template: string;
};

export type AdDetails = Omit<Ad, 'shop'> & {
  photo: never;
  photos: AssetUrl[];
  bookmarksCount: number;
  callsCount: number;
  hits: number;
  shop: ShopInAdDetails | null;
  contact: AdContact;
  status: AdStatus;
  properties: AdProperty[];
  legacyResourceId: number;
  birkartBanner: {
    hasCard: boolean;
    hasModal: boolean;
    hasKredit: boolean;
  };
  body: string;
  relatedAds: Ad[];
  user: {
    id: string;
  };
  reportReasons: ReportReason[];
  expiresAt: string | null;
  chatPath: string;
  vippedUntil: string | null;
  featuredUntil: string | null;
  bumpServices: AdService[];
  featuredServices: AdService[];
  vipServices: AdService[];
  rejectReasons: RejectReason[] | null;
  isCallsAvailable: boolean;
  isChatAvailable: boolean;
  editLockedUntil: string | null;
  isExpiredManually: boolean;
};

export type GetAdsResponse = {
  ads: {
    nodes: Ad[];
    pageInfo: PageInfo;
    totalCount: number;
  };
};

export type GetAdsForBookmarksResponse = {
  ads: {
    nodes: Ad[];
  };
};

export type GetAdDetailsResponse = {
  adDetails: AdDetails;
};

export type ProlongAdResponse = {
  prolongAd: MutationResponse<boolean>;
};
