import React, { useEffect, useRef, useState } from 'react';
import {
  Category,
  GetCategoryDetailsResponse,
  GetChildPropertyOptionsResponse,
  PropertyOption,
} from '@/api/types/categories.types';
import { useCustomPush } from '@/components/CustomLink/CustomLink.hooks';
import { createNewUrlWithPropertyOption } from '@/components/SubcategoriesScrollGrid/SubcategoriesScrollGrid.helpers';
import { useQuery } from '@apollo/client';
import {
  GET_CATEGORY_DETAILS,
  GET_CHILD_PROPERTY_OPTIONS,
} from '@/api/queries/categories.queries';
import { getCategorySlug } from '@/components/FiltersDrawer/FiltersDrawer.helpers';
import { useCategories } from '@/store/useCategories';
import { useTranslation } from 'react-i18next';
import {
  CollectionSelectDrawer,
  CollectionSelectDrawerRef,
} from '@/components/FiltersDrawer/CollectionSelectDrawer';
import { useParseQuery } from '@/components/AdsWithInfiniteScroll/useParseQuery';
import { useCurrentCategory } from '@/hooks/useCurrentCategory';
import * as S from './SubcategoriesScrollGrid.styles';

interface SubcategoriesScrollGridProps {
  subcategories?: Category[] | null;
}

export const SubcategoriesScrollGrid = ({
  subcategories,
}: SubcategoriesScrollGridProps) => {
  const { t } = useTranslation();
  const customPush = useCustomPush();
  const [currentCategory] = useCategories(state => [state.currentCategory]);
  const slug = getCategorySlug(currentCategory);
  const collectionSelectDrawerRef = useRef<CollectionSelectDrawerRef>(null);
  const [list, setList] = useState<(Category | PropertyOption)[]>([]);

  const { data: categoryDetails } = useQuery<GetCategoryDetailsResponse>(
    GET_CATEGORY_DETAILS,
    { skip: !slug, variables: { slug } }
  );
  const { properties } = categoryDetails?.category ?? {};

  const { refetch } = useQuery<GetChildPropertyOptionsResponse>(
    GET_CHILD_PROPERTY_OPTIONS,
    { skip: true }
  );

  const [popularOptions, setPopularOptions] = useState<PropertyOption[]>([]);
  const [options, setOptions] = useState<PropertyOption[]>([]);
  const { collectionPropertyOptions } = useParseQuery();
  const { isSubcategories } = useCurrentCategory();

  const isLevel2PropertySelected = collectionPropertyOptions.length > 1;

  async function processBothOptions(
    legacyId: string | undefined,
    parentLegacyId: string
  ) {
    if (!legacyId) return;

    const { data } = await refetch({ legacyId, parentLegacyId });
    setPopularOptions(data.property.popularOptions);
    setOptions(data.property.options);
  }

  useEffect(() => {
    if (collectionPropertyOptions.length) {
      const { legacyId, value } = collectionPropertyOptions[0];
      const parentLegacyId = properties?.find(
        el => el.parentLegacyId === legacyId.toString()
      )?.id;
      processBothOptions(parentLegacyId, value);
    } else {
      const hasProperties = properties?.length;
      setPopularOptions(hasProperties ? properties?.[0].popularOptions : []);
      setOptions(hasProperties ? properties?.[0].options : []);
    }
  }, [properties, collectionPropertyOptions]);

  useEffect(() => {
    setList(() => {
      return subcategories
        ? subcategories
        : popularOptions?.length
        ? popularOptions
        : options;
    });
  }, [subcategories, popularOptions, options]);

  async function onPropertyOptionClick(propertyOption: PropertyOption) {
    if (propertyOption.childrenPropertyIds.length === 1) {
      const { data } = await refetch({
        legacyId: propertyOption.childrenPropertyIds[0],
        parentLegacyId: propertyOption.legacyResourceId,
      });
      setPopularOptions(data.property.popularOptions);
      setOptions(data.property.options);
    }

    if (
      !propertyOption.childrenPropertyIds.length ||
      propertyOption.childrenPropertyIds.length > 1
    ) {
      setPopularOptions([]);
      setOptions([]);
    }

    await goToPropertyOption(propertyOption);
  }

  async function goToPropertyOption(propertyOption: PropertyOption) {
    const newUrl = createNewUrlWithPropertyOption(propertyOption);
    await customPush(newUrl);
  }

  async function onClick(subcategory: Category | PropertyOption) {
    if ('path' in subcategory && subcategory.path) {
      await customPush(`/${subcategory.path}`);
    }

    if ('propertyLegacyId' in subcategory) {
      await onPropertyOptionClick(subcategory);
    }
  }

  function toggleDrawer() {
    collectionSelectDrawerRef.current?.toggle();
  }

  if (
    isSubcategories &&
    ((!options.length && !popularOptions.length) || isLevel2PropertySelected)
  ) {
    return null;
  }

  return (
    <S.SubcategoriesScrollGridBox>
      {list?.map(subcategory => {
        return (
          <S.SubcategoriesScrollGridItem
            key={subcategory.name}
            onClick={() => onClick(subcategory)}
          >
            {subcategory.name}
          </S.SubcategoriesScrollGridItem>
        );
      })}
      {!!popularOptions?.length && (
        <>
          <S.SubcategoriesScrollGridItem onClick={toggleDrawer}>
            {t('common.all_list')}
          </S.SubcategoriesScrollGridItem>
          <CollectionSelectDrawer
            options={options}
            allProperties={properties}
            popularOptions={popularOptions}
            ref={collectionSelectDrawerRef}
            propertyLegacyId={popularOptions[0].propertyLegacyId}
            onItemClick={option => {
              onPropertyOptionClick(option);
              toggleDrawer();
            }}
          />
        </>
      )}
    </S.SubcategoriesScrollGridBox>
  );
};
