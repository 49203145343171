import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const useIsRouterFirstLoad = () => {
  const router = useRouter();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    const initialUrl = sessionStorage.getItem('initialUrl');
    const currentUrl = window.location.href;
    if (!initialUrl) {
      sessionStorage.setItem('initialUrl', currentUrl);
    }
    setIsFirstLoad(currentUrl === initialUrl || window.history.length === 1);
  }, [router.asPath]);

  return isFirstLoad;
};
