import React, { useEffect, useRef, useState } from 'react';
import { Select } from '@/components/UI/Select/Select';
import {
  GetChildPropertyOptionsResponse,
  Property,
  PropertyOption,
} from '@/api/types/categories.types';
import { Collection } from '@/components/FiltersDrawer/FiltersDrawer.types';
import {
  groupByPropertyLegacyId,
  isCollectionCleared,
} from '@/components/FiltersDrawer/CollectionSelect.helpers';
import {
  CollectionSelectDrawer,
  CollectionSelectDrawerRef,
} from '@/components/FiltersDrawer/CollectionSelectDrawer';
import { useQuery } from '@apollo/client';
import { GET_CHILD_PROPERTY_OPTIONS } from '@/api/queries/categories.queries';

type Props = {
  propertyLegacyId: string;
  options: PropertyOption[];
  collection: Collection;
  setCollection: React.Dispatch<React.SetStateAction<Collection>>;
  allProperties?: Property[];
  popularOptions?: PropertyOption[];
  isFiltersOpen?: boolean;
};

export const CollectionSelect = ({
  propertyLegacyId,
  options,
  collection,
  setCollection,
  allProperties,
  popularOptions,
  isFiltersOpen,
}: Props) => {
  const [childPropertyOptions, setChildPropertyOptions] = useState<
    PropertyOption[]
  >([]);
  const collectionSelectDrawerRef = useRef<CollectionSelectDrawerRef>(null);

  const propertyValue = collection[propertyLegacyId];
  const name = allProperties?.find(el => el.id === propertyLegacyId)?.name;
  const mainProperty = allProperties?.find(el => el.options?.length);

  function toggleDrawer() {
    collectionSelectDrawerRef.current?.toggle();
  }

  const { refetch } = useQuery<GetChildPropertyOptionsResponse>(
    GET_CHILD_PROPERTY_OPTIONS,
    { skip: true }
  );

  async function onOptionClick(value: PropertyOption) {
    const isMainProperyClicked = value.propertyLegacyId === mainProperty?.id;

    if (isMainProperyClicked) {
      setCollection({ [value.propertyLegacyId]: value.legacyResourceId });
      setChildPropertyOptions([]);
    } else {
      setCollection(prev => ({
        ...prev,
        [value.propertyLegacyId]: value.legacyResourceId,
      }));
    }

    toggleDrawer();

    for await (const childrenPropertyId of value.childrenPropertyIds) {
      const { data } = await refetch({
        legacyId: childrenPropertyId,
        parentLegacyId: value.legacyResourceId,
      });

      setChildPropertyOptions(prev => [...prev, ...data.property.options]);
    }
  }

  const selected = options.find(
    el => el.legacyResourceId.toString() === propertyValue
  );

  const groupedChildOptions = groupByPropertyLegacyId(
    isCollectionCleared(collection) ? [] : childPropertyOptions
  );

  async function onOpenFilterDrawer() {
    const prop = allProperties?.filter(
      el => el.parentLegacyId === propertyLegacyId
    );

    if (prop?.length && propertyValue) {
      for await (const childProp of prop) {
        const legacyId = childProp.id;

        const { data } = await refetch({
          legacyId,
          parentLegacyId: propertyValue,
        });
        setChildPropertyOptions(prev => {
          return [...prev, ...data.property.options];
        });
      }
    }
  }

  useEffect(() => {
    if (isFiltersOpen) {
      onOpenFilterDrawer();
    } else {
      setChildPropertyOptions([]);
    }
  }, [isFiltersOpen, collection]);

  useEffect(() => {
    if (isCollectionCleared(collection)) {
      setChildPropertyOptions([]);
    }
  }, [collection]);

  if (!name || !options.length) return null;

  return (
    <>
      <Select label={name} toggle={toggleDrawer} value={selected?.name ?? ''}>
        <CollectionSelectDrawer
          propertyLegacyId={propertyLegacyId}
          options={options}
          collection={collection}
          setCollection={setCollection}
          allProperties={allProperties}
          popularOptions={popularOptions}
          ref={collectionSelectDrawerRef}
          onItemClick={onOptionClick}
        />
      </Select>

      {groupedChildOptions.map(group => {
        return (
          <CollectionSelect
            key={group[0].propertyLegacyId}
            propertyLegacyId={group[0].propertyLegacyId}
            options={group}
            collection={collection}
            setCollection={setCollection}
            allProperties={allProperties}
            isFiltersOpen={isFiltersOpen}
          />
        );
      })}
    </>
  );
};
