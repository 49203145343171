import { create } from 'zustand';
import { Ad } from '@/api/types/common.types';

export type AdsNextCursor = string;
export type SetAdsNextCursor = (value: AdsNextCursor) => void;

interface AdsStore {
  premiumAdsHomePage: Ad[];
  setPremiumAdsHomePage: (value: Ad[]) => void;
  premiumAdsNextCursorHomePage: AdsNextCursor;
  setPremiumAdsNextCursorHomePage: SetAdsNextCursor;

  vipAdsHomePage: Ad[];
  setVipAdsHomePage: (value: Ad[]) => void;
  vipAdsNextCursorHomePage: AdsNextCursor;
  setVipAdsNextCursorHomePage: SetAdsNextCursor;
}

export const useAdsStore = create<AdsStore>(set => ({
  premiumAdsHomePage: [],
  setPremiumAdsHomePage: value => set({ premiumAdsHomePage: value }),
  premiumAdsNextCursorHomePage: '',
  setPremiumAdsNextCursorHomePage: value =>
    set({ premiumAdsNextCursorHomePage: value }),

  vipAdsHomePage: [],
  setVipAdsHomePage: value => set({ vipAdsHomePage: value }),
  vipAdsNextCursorHomePage: '',
  setVipAdsNextCursorHomePage: value =>
    set({ vipAdsNextCursorHomePage: value }),
}));
