import { EMPTY_RESULTS } from '@/components/FiltersDrawer/RangeOptions.constants';
import { FieldType } from '@/components/FiltersDrawer/RangeOptions.types';

export function getUpdatedCollection(
  value: string | null,
  type: FieldType,
  values?: [number | string, number | string]
) {
  const updatedCollection: [number | string, number | string] = [
    ...(values || EMPTY_RESULTS),
  ];

  if (type === 'from') {
    updatedCollection[0] = value !== null ? value : EMPTY_RESULTS[0];
  } else if (type === 'to') {
    updatedCollection[1] = value !== null ? value : EMPTY_RESULTS[1];
  }

  return updatedCollection;
}
