import { Category, GetCategoriesResponse } from '@/api/types/categories.types';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useCategories } from '@/store/useCategories';
import { useQuery } from '@apollo/client';
import { GET_CATEGORIES } from '@/api/queries/categories.queries';

type UseGetCurrentCategoryReturn = {
  category?: Category | null;
  isVipAds: boolean;
  isAllCategories: boolean;
  isFirstLevel: boolean;
  isSubcategories: boolean;
};

export function useCurrentCategory(): UseGetCurrentCategoryReturn {
  const router = useRouter();
  const { data } = useQuery<GetCategoriesResponse>(GET_CATEGORIES);

  const categories = data?.categories;

  const setCurrentCategory = useCategories(state => state.setCurrentCategory);

  const isVipAds = router.query.slug?.[0] === 'vip';
  const isAllCategories = !router.query.slug;
  const categorySlug = router.query.slug?.[0];
  const subcategorySlug = router.query.slug?.[1];
  const isFirstLevel = router.query.slug?.length === 1;
  const isSubcategories = router.query.slug?.length === 2;

  useEffect(() => {
    const candidate = categories?.find(c =>
      c.path?.includes(categorySlug ?? '')
    );
    const rootCategory = categories?.[0].parent;

    if (!categorySlug && rootCategory) {
      return setCurrentCategory(rootCategory);
    }

    const result = isFirstLevel
      ? candidate
      : candidate?.children?.find(c => c.path?.includes(subcategorySlug ?? ''));
    setCurrentCategory(result);
  }, [router.pathname, categories, isSubcategories, isFirstLevel]);

  return { isVipAds, isAllCategories, isFirstLevel, isSubcategories };
}
