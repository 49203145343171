import React, { useState } from 'react';
import { RequiredStar } from '@/components/UI/RequiredStar/RequiredStar';
import { formatPhoneNumber } from '@/utils/helpers';
import { HelperText } from '@/components/UI/HelperText/HelperText';
import { InputProps } from '@/components/UI/Input/Input.types';
import { useCombinedRefs } from '@/hooks/useCombinedRefs';
import { getFinalValue } from '@/components/UI/Input/Input.helpers';
import { CommonTestIds } from '@/__e2e__/testIds/common';
import * as S from './Input.styles';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      appearance,
      clear,
      label,
      helperText,
      hasError,
      hasBorderBottom = true,
      isBigText,
      isNumber,
      isNumberWithSpaces,
      ...props
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const combinedRef = useCombinedRefs<HTMLInputElement | null>(ref, inputRef);
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onFocus?.(e);
      setIsFocused(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      setIsFocused(false);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value, selectionStart } = e.target;
      const isTel = props.type === 'tel';
      const formattedValue = formatPhoneNumber(value);

      const finalValue = getFinalValue({
        value,
        formattedValue,
        isTel,
        isNumberWithSpaces,
        isNumber,
      });

      props.onChange?.(finalValue);

      if (combinedRef.current) {
        combinedRef.current.value = finalValue;
      }

      if (isTel) {
        const adjustedCursorPosition =
          (selectionStart ?? 0) + (formattedValue.length - value.length);

        setTimeout(() => {
          combinedRef.current?.setSelectionRange(
            adjustedCursorPosition,
            adjustedCursorPosition
          );
        }, 0);
      }
    };

    const placeholder = label
      ? isFocused
        ? props.placeholder
        : ''
      : props.placeholder;

    if (clear && appearance !== 'filledWithIcon') {
      return (
        <S.InputWrapper>
          <S.InputBox
            data-testid={CommonTestIds.input.input}
            ref={combinedRef}
            {...props}
            placeholder={placeholder}
            onChange={onChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            $appearance={appearance}
            $withClear={!!clear}
            $hasBorderBottom={hasBorderBottom || !!helperText}
            $isBigText={isBigText}
          />
          {!!props.value && <S.InputClear onClick={clear} />}
        </S.InputWrapper>
      );
    }

    return (
      <S.InputWrapper>
        {appearance === 'filledWithIcon' && <S.InputFilledBg />}

        {appearance === 'filledWithIcon' && <S.SearchLeftIcon />}

        {label && (
          <S.InputLabel
            as="label"
            $variant="3"
            htmlFor={props.name}
            $isShifted={!!props.value || isFocused}
          >
            {label} {props.required && <RequiredStar />}
          </S.InputLabel>
        )}

        <S.InputBox
          data-testid={CommonTestIds.input.input}
          ref={combinedRef}
          {...props}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          $appearance={appearance}
          $hasBorderBottom={hasBorderBottom || !!helperText}
          $isBigText={isBigText}
        />

        {appearance === 'filledWithIcon' && !!props.value && (
          <S.InputClear onClick={clear} $hasLeftPadding />
        )}

        {helperText && (
          <HelperText helperText={helperText} hasError={hasError} />
        )}
      </S.InputWrapper>
    );
  }
);

Input.displayName = 'Input';
