import { Checkbox } from '@/components/UI/Checkbox/Checkbox';
import React from 'react';
import { PropertyOption } from '@/api/types/categories.types';
import { Collection } from '@/components/FiltersDrawer/FiltersDrawer.types';

type Props = {
  option: PropertyOption;
  collection?: Collection;
  onOptionClick: (option: PropertyOption) => void;
};

export const CollectionSelectOption = ({
  option,
  collection,
  onOptionClick,
}: Props) => {
  const isChecked =
    option.legacyResourceId.toString() ===
    collection?.[option.propertyLegacyId];

  return (
    <Checkbox
      onChangeHook={() => onOptionClick(option)}
      key={option.name}
      value={isChecked}
      label={option.name}
      appearance="radioCheckIcon"
      size="sm"
    />
  );
};
