import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getReversedLangDefinition,
  makeUrlWithLang,
  sortByObjectProperty,
} from '@/utils/helpers';
import { useQuery } from '@apollo/client';
import { GetAdsForBookmarksResponse } from '@/api/types/ads.types';
import { GET_ADS_FOR_BOOKMARKS } from '@/api/queries/ads.queries';
import {
  getStoredBookmarks,
  setStoredBookmarks,
  updateStoredBookmarks,
} from '@/store/useBookmarksStore/useBookmarksStore.helpers';
import { useRouter } from 'next/router';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import { QUERY } from '@/constants/common';
import { useAuthStore } from '@/store/useAuthStore';

type QueryBookmarksIdsWithOrder = Record<string, number>;

export function useChangeLang() {
  const { i18n } = useTranslation();
  const router = useRouter();
  const [isAuthenticated] = useAuthStore(state => [state.isAuthenticated]);

  const [setBookmarks, setBookmarkIds] = useBookmarksStore(state => [
    state.setBookmarks,
    state.setBookmarkIds,
  ]);

  const { refetch: getAdsForBookmarks } = useQuery<GetAdsForBookmarksResponse>(
    GET_ADS_FOR_BOOKMARKS,
    { skip: true }
  );

  const createNewLocation = () => {
    const { pathname, host, protocol } = window.location;
    return makeUrlWithLang({
      protocol: protocol.slice(0, -1),
      host,
      path: pathname,
      lang: getReversedLangDefinition(i18n.language).short,
    });
  };

  const handleAuthenticatedUser = (newLocation: string) => {
    window.location.replace(newLocation);
  };

  const handleUnauthenticatedUser = (newLocation: string) => {
    const storedBookmarks = getStoredBookmarks();

    const queryBookmarksIdsWithOrder =
      storedBookmarks.reduce<QueryBookmarksIdsWithOrder>(
        (acc, bookmark) => ({ ...acc, [bookmark.id]: bookmark.order }),
        {}
      );

    setStoredBookmarks([]);

    const { searchParams } = new URL(newLocation);
    const hasStoredBookmarks = !!Object.keys(queryBookmarksIdsWithOrder).length;

    if (hasStoredBookmarks) {
      searchParams.set(
        QUERY.bookmarksIds,
        JSON.stringify(queryBookmarksIdsWithOrder)
      );
      searchParams.set(QUERY.hasLangChanged, 'true');
    }

    const newLocationWithSearchParams = hasStoredBookmarks
      ? `${newLocation}?${searchParams.toString()}`
      : newLocation;

    window.location.replace(newLocationWithSearchParams);
  };

  function changeLang() {
    const newLocation = createNewLocation();

    if (isAuthenticated) {
      handleAuthenticatedUser(newLocation);
    } else {
      handleUnauthenticatedUser(newLocation);
    }
  }

  const fetchAndSetBookmarks = async () => {
    if (isAuthenticated) return;

    const queryBookmarksIdsWithOrderMap = JSON.parse(
      (router.query?.bIds as string) || '{}'
    ) as QueryBookmarksIdsWithOrder;

    const queryBookmarksIdsWithOrder = Object.keys(
      queryBookmarksIdsWithOrderMap
    );

    if (!queryBookmarksIdsWithOrder.length) return;

    const {
      data: {
        ads: { nodes: fetchedBookmarks },
      },
    } = await getAdsForBookmarks({
      ids: queryBookmarksIdsWithOrder,
    });

    const bookmarksWithOrder = fetchedBookmarks.map((ad, i) => {
      const order =
        queryBookmarksIdsWithOrderMap[ad.id] || Date.now() + (i + 1_000);

      return { ...ad, order };
    });

    const sortedBookmarks = sortByObjectProperty(bookmarksWithOrder, 'order');

    updateStoredBookmarks(sortedBookmarks);
    setBookmarkIds(fetchedBookmarks);
    setBookmarks(sortedBookmarks);

    await router.replace(router.pathname);
  };

  useEffect(() => {
    fetchAndSetBookmarks();
  }, []);

  return changeLang;
}
