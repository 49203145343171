const categoriesMockAz = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Daşınmaz əmlak',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az ev alqı satqısı, eləcə də kiraye ev elanları yerləşdirmək və tapmaq üçün ideal məkandır. Burada hər növ daşınmaz əmlakı (mənzillər, evler, ofislər və s.) rahatlıqla ala və ya qısa vaxtda sata bilərsiniz.',
      warningMessage: '',
      count: 32555,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
          name: 'Mənzillər',
          path: 'elanlar/dasinmaz-emlak/menziller',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 16632,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '7',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '8',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '9',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '10',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '11',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
          name: 'Villalar, bağ evləri',
          path: 'elanlar/dasinmaz-emlak/baglar-ve-villalar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 10178,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '12',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '13',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '14',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '15',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '16',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
          name: 'Torpaq',
          path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 3106,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '17',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '18',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '19',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '20',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
          name: 'Qarajlar',
          path: 'elanlar/dasinmaz-emlak/qarajlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 58,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '25',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '26',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
          name: 'Xaricdə əmlak',
          path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 5,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '27',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '28',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
          name: 'Obyektlər və ofislər',
          path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 2576,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Daşınmaz əmlak',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '21',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '22',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '23',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '24',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Elektronika',
      path: 'elanlar/elektronika',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az telefonlar və planşetlərdən çeşidli aksesuarlara qədər hər növ yeni və işlənmiş elektronika məhsulları üzrə çox sərfəli təkliflərin ünvanıdır – həm alıcılar, həm də satıcılar üçün sərfəli.',
      warningMessage: '',
      count: 30403,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
          name: 'Audio və video',
          path: 'elanlar/elektronika/audio-video',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1636,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '165',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '166',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '167',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '168',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '169',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
          name: 'Kompüter aksesuarları',
          path: 'elanlar/elektronika/komputer-aksesuarlari',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1054,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '170',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '171',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '172',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '173',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '174',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
          name: 'Oyunlar, pultlar və proqramlar',
          path: 'elanlar/elektronika/oyunlar-ve-programlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1910,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '175',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '176',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '177',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '178',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '179',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
          name: 'Masaüstü kompüterlər',
          path: 'elanlar/elektronika/komputerler',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 785,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '180',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '181',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '182',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '183',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '184',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
          name: 'Komponentlər və monitorlar',
          path: 'elanlar/elektronika/komputer-avadanliqi',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1949,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '185',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '186',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '187',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '188',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '189',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
          name: 'Planşet və elektron kitablar',
          path: 'elanlar/elektronika/plansetler',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 624,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '190',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '191',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '192',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '193',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '194',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
          name: 'Noutbuklar və netbuklar',
          path: 'elanlar/elektronika/noutbuklar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 2772,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '195',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '196',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '197',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '198',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '199',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
          name: 'Ofis avadanlığı və istehlak materialları',
          path: 'elanlar/elektronika/ofis-avadanliqi',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 614,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '200',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '201',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '202',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '203',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '204',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
          name: 'Telefonlar',
          path: 'elanlar/elektronika/telefonlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 11895,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '205',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '206',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '207',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '208',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '209',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
          name: 'Nömrələr və SIM-kartlar',
          path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 2700,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '3',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '4',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '5',
              count: 15,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '6',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
          name: 'Fototexnika',
          path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 855,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '210',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '211',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '212',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '213',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '214',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
          name: 'Smart saat və qolbaqlar',
          path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 911,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '215',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '216',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '217',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '218',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '219',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
          name: 'Televizorlar və aksesuarlar',
          path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1808,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '220',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '221',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '222',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '223',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '224',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
          name: 'Şəbəkə və server avadanlığı',
          path: 'elanlar/elektronika/sebeke-avadanligi',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 890,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Elektronika',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '372',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '373',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '374',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '375',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '376',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Nəqliyyat',
      path: 'elanlar/neqliyyat',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az avtomobil almaq və ya satmaq istəyənləri bir araya gətirir. Yeni və sürülmüş nəqliyyat vasitələrini, avtomobillər üçün ehtiyat hissə və aksesuarları əla qiymətlərlə alın və ya satın.',
      warningMessage: '',
      count: 28611,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
          name: 'Avtomobillər',
          path: 'elanlar/neqliyyat/avtomobiller',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 5716,
          bundles: [
            {
              id: '30',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '31',
              count: 5,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '32',
              count: 10,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
          name: 'Ehtiyat hissələri və aksesuarlar',
          path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
          count: 20393,
          icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          bundles: [
            {
              id: '33',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '34',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '35',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '38',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '39',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
          name: 'Su nəqliyyatı',
          path: 'elanlar/neqliyyat/su-neqliyyati',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 71,
          bundles: [
            {
              id: '40',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
          name: 'Motosikletlər və mopedlər',
          path: 'elanlar/neqliyyat/motosikletler-mopedler',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 798,
          bundles: [
            {
              id: '41',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
          name: 'Tikinti texnikası',
          path: 'elanlar/neqliyyat/tikinti-texnikasi',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 218,
          bundles: [
            {
              id: '42',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
          name: 'Aqrotexnika',
          path: 'elanlar/neqliyyat/aqrotexnika',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 302,
          bundles: [
            {
              id: '43',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '44',
              count: 5,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
          name: 'Avtobuslar',
          path: 'elanlar/neqliyyat/avtobuslar',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 16,
          bundles: [
            {
              id: '363',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
          name: 'Yük maşınları və qoşqular',
          path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 509,
          bundles: [
            {
              id: '364',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
          name: 'Qeydiyyat nişanları',
          path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Nəqliyyat',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 588,
          bundles: [
            {
              id: '387',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '388',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '389',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '390',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Ev və bağ üçün',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
      __typename: 'Category',
      metaDescription:
        'Təmir-tikinti üçün hər şey, mebel, yeni və islenmis ev esyalari, yeni və ikinci əl mətbəx ləvazimatları, məişət texnikası, bir sözlə, ev və bağ üçün əşyalar ilə bağlı hər şeyi burada tapa və sata bilərsiniz.',
      warningMessage: '',
      count: 27516,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
          name: 'Təmir və tikinti',
          path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 4856,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '54',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '55',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '56',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '57',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '58',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
          name: 'Mebellər',
          path: 'elanlar/ev-ve-bag-ucun/mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 8781,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '45',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '46',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '47',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '48',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '49',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
          name: 'Məişət texnikası',
          path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 6692,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '59',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '60',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '61',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '62',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '63',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
          name: 'Ərzaq',
          path: 'elanlar/ev-ve-bag-ucun/erzaq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 265,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '72',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '73',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '74',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '75',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
          name: 'Qab-qacaq və mətbəx ləvazimatları',
          path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2017,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '64',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '65',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '66',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '67',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
          name: 'Bitkilər',
          path: 'elanlar/ev-ve-bag-ucun/bitkiler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 570,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '68',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '69',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '70',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '71',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
          name: 'Xalçalar və aksesuarlar',
          path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 762,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '76',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '77',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '78',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '79',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
          name: 'Ev tekstili',
          path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 648,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '80',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '81',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '82',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '83',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
          name: 'Ev və bağ üçün işiqlandırma',
          path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 818,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '84',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '85',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '86',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '87',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
          name: 'Dekor və interyer',
          path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1045,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '88',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '89',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '90',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '91',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
          name: 'Bağ və bostan',
          path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 865,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '92',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '93',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '94',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '95',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
          name: 'Ev təsərrüfatı malları',
          path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 197,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Ev və bağ üçün',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '365',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '366',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '367',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '368',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Ehtiyat hissələri və aksesuarlar',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da avtomobil ehtiyat hissələri və aksesuarlar çox sərfəli qiymətlərlə təklif olunur. Mağazaları və maşın "ölüxana"larını gəzməyi unudun.',
      warningMessage:
        'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
      count: 20393,
      propertyOptions: [
        {
          id: '42255',
          propertyId: '798',
          name: 'Avtomobil üçün alətlər',
          __typename: 'PropertyOption',
        },
        {
          id: '4050',
          propertyId: '798',
          name: 'Şinlər, disklər və təkərlər',
          __typename: 'PropertyOption',
        },
        {
          id: '4048',
          propertyId: '798',
          name: 'Siqnalizasiyalar',
          __typename: 'PropertyOption',
        },
        {
          id: '7476',
          propertyId: '798',
          name: 'Videoqeydiyyatçılar',
          __typename: 'PropertyOption',
        },
        {
          id: '4045',
          propertyId: '798',
          name: 'Aksesuarlar',
          __typename: 'PropertyOption',
        },
        {
          id: '4046',
          propertyId: '798',
          name: 'Audio və video texnika',
          __typename: 'PropertyOption',
        },
        {
          id: '4044',
          propertyId: '798',
          name: 'Avto kosmetika və kimya',
          __typename: 'PropertyOption',
        },
        {
          id: '4051',
          propertyId: '798',
          name: 'Digər',
          __typename: 'PropertyOption',
        },
        {
          id: '7693',
          propertyId: '798',
          name: 'Diaqnostika cihazları',
          __typename: 'PropertyOption',
        },
        {
          id: '4047',
          propertyId: '798',
          name: 'Ehtiyat hissələri',
          __typename: 'PropertyOption',
        },
        {
          id: '4043',
          propertyId: '798',
          name: 'GPS-naviqatorlar',
          __typename: 'PropertyOption',
        },
        {
          id: '4119',
          propertyId: '798',
          name: 'Qeydiyyat nişanları',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Nəqliyyat',
        count: 28611,
        path: 'elanlar/neqliyyat',
        icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
        __typename: 'Category',
      },
      children: [],
      bundles: [
        {
          id: '33',
          count: 1,
          price: 1,
          __typename: 'Bundle',
        },
        {
          id: '34',
          count: 5,
          price: 3,
          __typename: 'Bundle',
        },
        {
          id: '35',
          count: 10,
          price: 5,
          __typename: 'Bundle',
        },
        {
          id: '38',
          count: 25,
          price: 11,
          __typename: 'Bundle',
        },
        {
          id: '39',
          count: 50,
          price: 20,
          __typename: 'Bundle',
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Şəxsi əşyalar',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az istifadəçiləri burada yeni və işlənmiş geyimlərini, aksesuarlarını və digər əşyalarını sataraq gəlir əldə edir, həmçinin itirdikləri və ya tapdıqları əşyalar barədə elanlar yerləşdirir.',
      warningMessage: '',
      count: 9478,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
          name: 'Geyim və ayaqqabılar',
          path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 4054,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '96',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '97',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '98',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '99',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
          name: 'Saat və zinət əşyaları',
          path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2279,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '100',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '101',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '102',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '103',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
          name: 'Aksesuarlar',
          path: 'elanlar/sexsi-esyalar/aksesuarlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1185,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '104',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '105',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '106',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '107',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
          name: 'Sağlamlıq və gözəllik',
          path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1590,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '108',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '109',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '110',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '111',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
          name: 'İtmiş əşyalar',
          path: 'elanlar/sexsi-esyalar/itmis-esyalar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
          name: 'Elektron siqaretlər və tütün qızdırıcıları',
          path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 324,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Şəxsi əşyalar',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '343',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '344',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '345',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '346',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Xidmətlər və biznes',
      path: 'elanlar/xidmetler',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da təqdim olunan çoxsaylı xidmətlərdən (elektrik, fotoqraf, repetitor və s.) yararlana və ya mütəxəssisi olduğunuz sahə üzrə xidmətlərinizi təklif edərək davamlı qazanc əldə edə bilərsiniz.',
      warningMessage: '',
      count: 9423,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
          name: 'Avadanlığın icarəsi',
          path: 'elanlar/xidmetler/avadanliqin-icaresi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 116,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '225',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '226',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '227',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
          name: 'Biznes üçün avadanlıq',
          path: 'elanlar/xidmetler/biznes-avadaliqi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 5659,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '50',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '51',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '52',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '53',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
          name: 'Avadanlıqların quraşdırılması',
          path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 58,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '228',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '229',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '230',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
          name: 'Avtoservis və diaqnostika ',
          path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 67,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '231',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '232',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '233',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
          name: 'Dayələr, baxıcılar',
          path: 'elanlar/xidmetler/dayeler-baxicilar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 30,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '234',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '235',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '236',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
          name: 'Foto və video çəkiliş xidmətləri',
          path: 'elanlar/xidmetler/foto-ve-video-cekilis',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 26,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '237',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '238',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '239',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
          name: 'Gözəllik, sağlamlıq',
          path: 'elanlar/xidmetler/gozellik-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 35,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '240',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '241',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '242',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
          name: 'Hüquq xidmətləri',
          path: 'elanlar/xidmetler/huquq-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 9,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '243',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '244',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '245',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
          name: 'IT, internet, telekom',
          path: 'elanlar/xidmetler/komputer-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 84,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '246',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '247',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '248',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
          name: 'Logistika',
          path: 'elanlar/xidmetler/logistika',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 160,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '369',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '370',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '371',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
          name: 'Mebel yığılması və təmiri',
          path: 'elanlar/xidmetler/sifarisle-mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 103,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '249',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '250',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '251',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
          name: 'Musiqi, əyləncə və tədbirlər',
          path: 'elanlar/xidmetler/tedbirlerin-teskilati',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 41,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '252',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '253',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '254',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
          name: 'Mühasibat xidmətləri',
          path: 'elanlar/xidmetler/muhasibat-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 43,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '255',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '256',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '257',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
          name: 'Nəqliyyat vasitələrinin icarəsi',
          path: 'elanlar/xidmetler/neqliyyat-icaresi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 300,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '258',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '259',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '260',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
          name: 'Qidalanma, keyterinq',
          path: 'elanlar/xidmetler/keyterinq-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 54,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '261',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '262',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '263',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
          name: 'Reklam, dizayn və poliqrafiya',
          path: 'elanlar/xidmetler/reklam-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 188,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '264',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '265',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '266',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
          name: 'Sığorta xidmətləri',
          path: 'elanlar/xidmetler/sigorta-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 6,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '267',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '268',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '269',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
          name: 'Təhlükəsizlik sistemlərinin qurulması',
          path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 863,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '270',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '271',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '272',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '273',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
          name: 'Təlim, hazırlıq kursları',
          path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 251,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '274',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '275',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '276',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
          name: 'Təmir və tikinti',
          path: 'elanlar/xidmetler/temir-tikinti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 796,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '277',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '278',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '279',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '280',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
          name: 'Təmizlik',
          path: 'elanlar/xidmetler/temizlik-xidmeti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 63,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '281',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
          name: 'Tərcümə',
          path: 'elanlar/xidmetler/tercume-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 20,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '282',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '283',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '284',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
          name: 'Texnika təmiri',
          path: 'elanlar/xidmetler/texnika-temiri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 319,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '285',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '286',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '287',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
          name: 'Tibbi xidmətlər',
          path: 'elanlar/xidmetler/tibbi-xidmetler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 33,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '288',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '289',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '290',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
          name: 'Digər',
          path: 'elanlar/xidmetler/diger-xidmetler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 99,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Xidmətlər və biznes',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '291',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '292',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '293',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Hobbi və asudə',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
      __typename: 'Category',
      metaDescription:
        'Asudə vaxtınızda məşğul olmaq üçün maraqlı hobbi axtarırsınız? Yaxın həftələrdə səyahətə çıxmaq istəyirsiniz? Bəs ürəyinizə yatan bir insanla tanış olmaq necə? Elədirsə, bura tam sizlikdir.',
      warningMessage: '',
      count: 9048,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
          name: 'Biletlər və səyahət',
          path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 170,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '2',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '112',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '113',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '114',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
          name: 'Velosipedlər',
          path: 'elanlar/hobbi-ve-asude/velosipedler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 3070,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '115',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '116',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '117',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '118',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
          name: 'Kolleksiyalar',
          path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 919,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '119',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '120',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '121',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '122',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
          name: 'Musiqi alətləri',
          path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1055,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '123',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '124',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '125',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '126',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
          name: 'İdman və asudə',
          path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2657,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '127',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '128',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '129',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '130',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
          name: 'Kitab və jurnallar',
          path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 637,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '131',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '132',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '133',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '134',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
          name: 'Kempinq, ovçuluq və balıqçılıq',
          path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 383,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '135',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '136',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '137',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '138',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
          name: 'Tanışlıq',
          path: 'elanlar/hobbi-ve-asude/tanisliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 157,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Hobbi və asudə',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '29',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'İş elanları',
      path: 'elanlar/is-elanlari',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az hər zaman olduğu kimi iş var və ya is axtariram deyənlərin işini asanlaşdırır. Yeni is elanlari 2022 mövsümü üzrə daim yenilənir, yüzlərlə şəxs isə qısa zamanda axtardığı vakansiyalar üzrə iş tapır.',
      warningMessage: '',
      count: 6508,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
          name: 'Vakansiyalar',
          path: 'elanlar/is-elanlari/vakansiyalar',
          icon: null,
          warningMessage:
            'Dələduzlar işədüzəltmə adı altında sizdən əsassız ödənişlər (sığorta, rüsum və s.) tələb edə bilər!',
          __typename: 'Category',
          count: 5018,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'İş elanları',
            count: 6508,
            path: 'elanlar/is-elanlari',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '1',
              count: 1,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '163',
              count: 3,
              price: 9,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
          name: 'İş axtarıram',
          path: 'elanlar/is-elanlari/is-axtariram',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1490,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'İş elanları',
            count: 6508,
            path: 'elanlar/is-elanlari',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '164',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Heyvanlar',
      path: 'elanlar/heyvanlar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az müxtəlif heyvanlar üçün yeni sahib tapmaqda və ya onlara sahiblənməkdə sizə yardımçı olacaq. Burada axtardığınız heyvan növlərini, onlar üçün müxtəlif qida, əşya və oyuncaqları tapa bilərsiniz.',
      warningMessage: '',
      count: 6460,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
          name: 'İtlər',
          path: 'elanlar/heyvanlar/itler',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1416,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '139',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '140',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '141',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '142',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
          name: 'Pişiklər',
          path: 'elanlar/heyvanlar/pisikler',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 1218,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '143',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '144',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '145',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '146',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
          name: 'Quşlar',
          path: 'elanlar/heyvanlar/quslar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 2038,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '147',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '148',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '149',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '150',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
          name: 'Akvariumlar və balıqlar',
          path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 548,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '151',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '152',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '153',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '154',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
          name: 'K/t heyvanları',
          path: 'elanlar/heyvanlar/kt-heyvanlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 508,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '347',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '348',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '349',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '350',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
          name: 'Digər heyvanlar',
          path: 'elanlar/heyvanlar/diger-heyvanlar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '155',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '156',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '157',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '158',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
          name: 'Dovşanlar',
          path: 'elanlar/heyvanlar/dovsanlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 123,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '351',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '352',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '353',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '354',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
          name: 'Heyvanlar üçün məhsullar',
          path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
          icon: null,
          warningMessage:
            'Beh göndərməmişdən öncə sövdələşmənin təhlükəsiz olduğuna əmin olun!',
          __typename: 'Category',
          count: 461,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '159',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '160',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '161',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '162',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
          name: 'Atlar',
          path: 'elanlar/heyvanlar/atlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 55,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '355',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '356',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '357',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '358',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
          name: 'Gəmiricilər',
          path: 'elanlar/heyvanlar/gemiriciler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Heyvanlar',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '359',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '360',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '361',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '362',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Uşaq aləmi',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
      __typename: 'Category',
      metaDescription:
        'Tap.az-da hər yaşda uşaqlar üçün axtardığınız əşyaları (oyuncaqlar, geyim, usaq belekleri və s.) tapa və ya sata bilərsiniz. Məhsullar olduqca sərfəli qiymətə təqdim olunur və qısa müddətdə alıcı tapır.',
      warningMessage: '',
      count: 5337,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Bütün kateqoriyalar',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
          name: 'Avtomobil oturacaqları',
          path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 146,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '294',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '295',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '296',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '297',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
          name: 'Oyuncaqlar',
          path: 'elanlar/usaqlar-ucun/oyuncaqlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 721,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '298',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '299',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '300',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '301',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
          name: 'Uşaq arabaları',
          path: 'elanlar/usaqlar-ucun/usaq-arabalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1057,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '306',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '307',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '308',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
          name: 'Uşaq avtomobilləri',
          path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 369,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '309',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '310',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '311',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '312',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
          name: 'Uşaq çarpayıları və beşiklər',
          path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 972,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '313',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '314',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '315',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '316',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
          name: 'Uşaq daşıyıcıları',
          path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 73,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '317',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '318',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '319',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
          name: 'Uşaq geyimi',
          path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 590,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '320',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '321',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '322',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '323',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
          name: 'Uşaq mebeli',
          path: 'elanlar/usaqlar-ucun/mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 257,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '324',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '325',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '326',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '327',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
          name: 'Uşaq qidası və bəslənməsi',
          path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 48,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '377',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '378',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '379',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '380',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
          name: 'Uşaq sürüşkənləri və oyun meydançaları',
          path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 88,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '328',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '329',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '330',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
          name: 'Manejlər',
          path: 'elanlar/usaqlar-ucun/manejler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '331',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '332',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '333',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
          name: 'Məktəblilər üçün',
          path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 491,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '334',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '335',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '336',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
          name: 'Yürütəclər',
          path: 'elanlar/usaqlar-ucun/yurutecler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 117,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '337',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '338',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '339',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
          name: 'Hamam və gigiyena',
          path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 54,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '381',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '382',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '383',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
          name: 'Uşaq tekstili',
          path: 'elanlar/usaqlar-ucun/usaq-tekstili',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 42,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '384',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '385',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '386',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
          name: 'Qidalanma üçün uşaq oturacaqları',
          path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 232,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '302',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '303',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '304',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '305',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
          name: 'Digər',
          path: 'elanlar/usaqlar-ucun/her-sey',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 34,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Uşaq aləmi',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Bütün kateqoriyalar',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '340',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '341',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '342',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
  ],
};

const categoriesMockRu = {
  categories: [
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
      name: 'Недвижимость',
      path: 'elanlar/dasinmaz-emlak',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
      __typename: 'Category',
      metaDescription:
        'Квартиры, дома, дачи, коттеджи, земельные участки, объекты, офисы, гаражи, недвижимость за рубежом',
      warningMessage: '',
      count: 32555,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNQ',
          name: 'Квартиры',
          path: 'elanlar/dasinmaz-emlak/menziller',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 16632,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '7',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '8',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '9',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '10',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '11',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMQ',
          name: 'Дома, дачи, коттеджи',
          path: 'elanlar/dasinmaz-emlak/baglar-ve-villalar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 10178,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '12',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '13',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '14',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '15',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '16',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMg',
          name: 'Земельные участки',
          path: 'elanlar/dasinmaz-emlak/torpaq-sahesi',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 3106,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '17',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '18',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '19',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '20',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMw',
          name: 'Гаражи',
          path: 'elanlar/dasinmaz-emlak/qarajlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 58,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '25',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '26',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNA',
          name: 'Недвижимость за рубежом',
          path: 'elanlar/dasinmaz-emlak/xaricde-emlak',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 5,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '27',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '28',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNQ',
          name: 'Объекты и офисы',
          path: 'elanlar/dasinmaz-emlak/obyektler-ve-ofisler',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 2576,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNg',
            name: 'Недвижимость',
            count: 32555,
            path: 'elanlar/dasinmaz-emlak',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/real-estate-b5728534748062214a0067f592243103e71dd7227c0d8318445bd49cc882f800.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '21',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '22',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '23',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '24',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
      name: 'Электроника',
      path: 'elanlar/elektronika',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
      __typename: 'Category',
      metaDescription:
        'Аудио и видео, компьютерные аксессуары, телефоны, ноутбуки, нетбуки, настольные компьютеры, фототехника, планшеты, электронные книги, комплектующие, мониторы, игры, приставки, программы, номера и SIM-карты, оргтехника и расходники',
      warningMessage: '',
      count: 30403,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMQ',
          name: 'Аудио и видео',
          path: 'elanlar/elektronika/audio-video',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1636,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '165',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '166',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '167',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '168',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '169',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMg',
          name: 'Компьютерные аксессуары',
          path: 'elanlar/elektronika/komputer-aksesuarlari',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1054,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '170',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '171',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '172',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '173',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '174',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMw',
          name: 'Игры, приставки и программы',
          path: 'elanlar/elektronika/oyunlar-ve-programlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1910,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '175',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '176',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '177',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '178',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '179',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNA',
          name: 'Настольные компьютеры',
          path: 'elanlar/elektronika/komputerler',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 785,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '180',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '181',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '182',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '183',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '184',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNQ',
          name: 'Комплектующие и мониторы',
          path: 'elanlar/elektronika/komputer-avadanliqi',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1949,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '185',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '186',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '187',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '188',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '189',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNg',
          name: 'Планшеты и электронные книги',
          path: 'elanlar/elektronika/plansetler',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 624,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '190',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '191',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '192',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '193',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '194',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxNw',
          name: 'Ноутбуки и нетбуки',
          path: 'elanlar/elektronika/noutbuklar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 2772,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '195',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '196',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '197',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '198',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '199',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOA',
          name: 'Оргтехника и расходники',
          path: 'elanlar/elektronika/ofis-avadanliqi',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 614,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '200',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '201',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '202',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '203',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '204',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxOQ',
          name: 'Мобильные телефоны',
          path: 'elanlar/elektronika/telefonlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 11895,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '205',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '206',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '207',
              count: 10,
              price: 7,
              __typename: 'Bundle',
            },
            {
              id: '208',
              count: 25,
              price: 15,
              __typename: 'Bundle',
            },
            {
              id: '209',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOQ',
          name: 'Номера и SIM-карты',
          path: 'elanlar/elektronika/nomreler-ve-sim-kartlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 2700,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '3',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '4',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '5',
              count: 15,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '6',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMA',
          name: 'Фототехника',
          path: 'elanlar/elektronika/fotoaparatlar-ve-linzalar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 855,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '210',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '211',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '212',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '213',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '214',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MA',
          name: 'Смарт часы и браслеты',
          path: 'elanlar/elektronika/smart-saat-ve-qolbaqlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 911,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '215',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '216',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '217',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '218',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '219',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5MQ',
          name: 'Телевизоры и аксессуары',
          path: 'elanlar/elektronika/televizor-ve-aksesuarlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1808,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '220',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '221',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '222',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '223',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '224',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNw',
          name: 'Сетевое и серверное оборудование',
          path: 'elanlar/elektronika/sebeke-avadanligi',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 890,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMQ',
            name: 'Электроника',
            count: 30403,
            path: 'elanlar/elektronika',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/consumer-electronics-929efe539b565b372519e174e25ffa42dad52bfbde36586ce728c0c45de54abc.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '372',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '373',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '374',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '375',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '376',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
      name: 'Транспорт',
      path: 'elanlar/neqliyyat',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
      __typename: 'Category',
      metaDescription:
        'Автомобили, автозапчасти, аксессуары, автобусы и техника, мотоциклы, мопеды, водный транспорт, агротехника ',
      warningMessage: '',
      count: 28611,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mg',
          name: 'Легковые автомобили',
          path: 'elanlar/neqliyyat/avtomobiller',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 5716,
          bundles: [
            {
              id: '30',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '31',
              count: 5,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '32',
              count: 10,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
          name: 'Автозапчасти и аксессуары',
          path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
          count: 20393,
          icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          bundles: [
            {
              id: '33',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '34',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '35',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '38',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '39',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NA',
          name: 'Водный транспорт',
          path: 'elanlar/neqliyyat/su-neqliyyati',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 71,
          bundles: [
            {
              id: '40',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4NQ',
          name: 'Мотоциклы и мопеды',
          path: 'elanlar/neqliyyat/motosikletler-mopedler',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 798,
          bundles: [
            {
              id: '41',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Ng',
          name: 'Строительная техника',
          path: 'elanlar/neqliyyat/tikinti-texnikasi',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 218,
          bundles: [
            {
              id: '42',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Nw',
          name: 'Агротехника',
          path: 'elanlar/neqliyyat/aqrotexnika',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 302,
          bundles: [
            {
              id: '43',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '44',
              count: 5,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMw',
          name: 'Автобусы',
          path: 'elanlar/neqliyyat/avtobuslar',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 16,
          bundles: [
            {
              id: '363',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNA',
          name: 'Грузовики и прицепы',
          path: 'elanlar/neqliyyat/yuk-masinlari-ve-qosqular',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 509,
          bundles: [
            {
              id: '364',
              count: 1,
              price: 2,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcxMQ',
          name: 'Регистрационные номера',
          path: 'elanlar/neqliyyat/qeydiyyat-nisanlari',
          icon: null,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
            name: 'Транспорт',
            count: 28611,
            path: 'elanlar/neqliyyat',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
              count: 165339,
            },
          },
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 588,
          bundles: [
            {
              id: '387',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '388',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '389',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '390',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
      name: 'Для дома и дачи',
      path: 'elanlar/ev-ve-bag-ucun',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
      __typename: 'Category',
      metaDescription:
        'Ремонт и строительство, мебель и интерьер, бытовая техника, посуда и товары для кухни, растения',
      warningMessage: '',
      count: 27516,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OA',
          name: 'Ремонт и строительство',
          path: 'elanlar/ev-ve-bag-ucun/temir-tikinti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 4856,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '54',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '55',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '56',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '57',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '58',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4OQ',
          name: 'Мебель',
          path: 'elanlar/ev-ve-bag-ucun/mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 8781,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '45',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '46',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '47',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '48',
              count: 25,
              price: 11,
              __typename: 'Bundle',
            },
            {
              id: '49',
              count: 50,
              price: 20,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MA',
          name: 'Бытовая техника',
          path: 'elanlar/ev-ve-bag-ucun/meiset-texnikasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 6692,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '59',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '60',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '61',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '62',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
            {
              id: '63',
              count: 50,
              price: 15,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMw',
          name: 'Продукты питания',
          path: 'elanlar/ev-ve-bag-ucun/erzaq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 265,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '72',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '73',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '74',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '75',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5MQ',
          name: 'Посуда и товары для кухни',
          path: 'elanlar/ev-ve-bag-ucun/qab-qacaq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2017,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '64',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '65',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '66',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '67',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mg',
          name: 'Растения',
          path: 'elanlar/ev-ve-bag-ucun/bitkiler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 570,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '68',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '69',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '70',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '71',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mg',
          name: 'Ковры и аксессуары',
          path: 'elanlar/ev-ve-bag-ucun/xalcalar-aksesuarlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 762,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '76',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '77',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '78',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '79',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Mw',
          name: 'Домашний текстиль',
          path: 'elanlar/ev-ve-bag-ucun/ev-tekstili',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 648,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '80',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '81',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '82',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '83',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NA',
          name: 'Освещение для дома и дачи',
          path: 'elanlar/ev-ve-bag-ucun/ev-bag-ucun-ishiqlandirma',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 818,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '84',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '85',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '86',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '87',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5NQ',
          name: 'Декор и интерьер',
          path: 'elanlar/ev-ve-bag-ucun/dekor-interyer',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1045,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '88',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '89',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '90',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '91',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Ng',
          name: 'Дача и сад',
          path: 'elanlar/ev-ve-bag-ucun/bag-bostan',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 865,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '92',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '93',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '94',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '95',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNQ',
          name: 'Хозяйственные товары',
          path: 'elanlar/ev-ve-bag-ucun/ev-teserrufati-mallari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 197,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Mw',
            name: 'Для дома и дачи',
            count: 27516,
            path: 'elanlar/ev-ve-bag-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/house-and-cottage-60305c82573099b34adee5058683b9a369f55d8d8cc143a4a500f91e5a0961d1.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '365',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '366',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '367',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '368',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Mw',
      name: 'Автозапчасти и аксессуары',
      path: 'elanlar/neqliyyat/ehtiyyat-hisseleri-ve-aksesuarlar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/parts-accessories-b9011002045afa54419a868daa56dfb1b7ca4fcb7a3d27ea410e7803dc423ec3.png',
      __typename: 'Category',
      metaDescription:
        'Продажа аксессуаров и запчастей для автомобилей – Регистрационные номера, GPS-навигаторы.',
      warningMessage:
        'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
      count: 20393,
      propertyOptions: [
        {
          id: '42255',
          propertyId: '798',
          name: 'Автоинструменты',
          __typename: 'PropertyOption',
        },
        {
          id: '4050',
          propertyId: '798',
          name: 'Шины, диски и колёса',
          __typename: 'PropertyOption',
        },
        {
          id: '4048',
          propertyId: '798',
          name: 'Противоугонные устройства',
          __typename: 'PropertyOption',
        },
        {
          id: '7476',
          propertyId: '798',
          name: 'Видеорегистраторы',
          __typename: 'PropertyOption',
        },
        {
          id: '4045',
          propertyId: '798',
          name: 'Аксессуары',
          __typename: 'PropertyOption',
        },
        {
          id: '4046',
          propertyId: '798',
          name: 'Аудио и видеотехника',
          __typename: 'PropertyOption',
        },
        {
          id: '4044',
          propertyId: '798',
          name: 'Автокосметика и автохимия',
          __typename: 'PropertyOption',
        },
        {
          id: '4051',
          propertyId: '798',
          name: 'Другое',
          __typename: 'PropertyOption',
        },
        {
          id: '7693',
          propertyId: '798',
          name: 'Диагностические приборы',
          __typename: 'PropertyOption',
        },
        {
          id: '4047',
          propertyId: '798',
          name: 'Запчасти',
          __typename: 'PropertyOption',
        },
        {
          id: '4043',
          propertyId: '798',
          name: 'GPS-навигаторы',
          __typename: 'PropertyOption',
        },
        {
          id: '4119',
          propertyId: '798',
          name: 'Регистрационные номера',
          __typename: 'PropertyOption',
        },
      ],
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4Nw',
        name: 'Транспорт',
        count: 28611,
        path: 'elanlar/neqliyyat',
        icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/transport-270532286a45ce4a46d1ca2f6e9c144f7be2e7461162c3e7d1cb7d38b5b17db4.png',
        __typename: 'Category',
      },
      children: [],
      bundles: [
        {
          id: '33',
          count: 1,
          price: 1,
          __typename: 'Bundle',
        },
        {
          id: '34',
          count: 5,
          price: 3,
          __typename: 'Bundle',
        },
        {
          id: '35',
          count: 10,
          price: 5,
          __typename: 'Bundle',
        },
        {
          id: '38',
          count: 25,
          price: 11,
          __typename: 'Bundle',
        },
        {
          id: '39',
          count: 50,
          price: 20,
          __typename: 'Bundle',
        },
      ],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
      name: 'Личные вещи',
      path: 'elanlar/sexsi-esyalar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
      __typename: 'Category',
      metaDescription:
        'Одежда и обувь, часы и украшения, аксессуары, красота и здоровье, утерянные вещи',
      warningMessage: '',
      count: 9478,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwNw',
          name: 'Одежда и обувь',
          path: 'elanlar/sexsi-esyalar/geyim-ayaqqabilar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 4054,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '96',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '97',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '98',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '99',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNg',
          name: 'Часы и украшения',
          path: 'elanlar/sexsi-esyalar/saatlar-zinet-esyalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2279,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '100',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '101',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '102',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '103',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwOA',
          name: 'Аксессуары',
          path: 'elanlar/sexsi-esyalar/aksesuarlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1185,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '104',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '105',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '106',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '107',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzOA',
          name: 'Красота и здоровье',
          path: 'elanlar/sexsi-esyalar/gozellik-saglamliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1590,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '108',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '109',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '110',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '111',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0Mw',
          name: 'Утерянные вещи',
          path: 'elanlar/sexsi-esyalar/itmis-esyalar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OA',
          name: 'Электронные сигареты и системы нагревания табака',
          path: 'elanlar/sexsi-esyalar/elektron-siqaretler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 324,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYxMA',
            name: 'Личные вещи',
            count: 9478,
            path: 'elanlar/sexsi-esyalar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/personal-belongings-9313acb6b11299b18ead551a5446eeb5eeb7ff006ac4438facb7d8a0dbce6001.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '343',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '344',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '345',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '346',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
      name: 'Услуги и бизнес',
      path: 'elanlar/xidmetler',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
      __typename: 'Category',
      metaDescription:
        'Аренда и установка оборудования, ремонт и строительство, установка охранных систем, реклама, дизайн и полиграфия, ремонт техники, сборка и реставрация мебели, перевод, обучение, подготовительные курсы, IT, интернет, музыка, развлечения и мероприятия, пита',
      warningMessage: '',
      count: 9423,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OA',
          name: 'Аренда оборудования',
          path: 'elanlar/xidmetler/avadanliqin-icaresi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 116,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '225',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '226',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '227',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzMQ',
          name: 'Оборудование для бизнеса',
          path: 'elanlar/xidmetler/biznes-avadaliqi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 5659,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '50',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '51',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '52',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '53',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Nw',
          name: 'Установка оборудования',
          path: 'elanlar/xidmetler/avadanliqin-qurasdirilmasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 58,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '228',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '229',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '230',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MQ',
          name: 'Автосервис и диагностика',
          path: 'elanlar/xidmetler/avtoservis-ve-diaqnostika',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 67,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '231',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '232',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '233',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mw',
          name: 'Няни, сиделки',
          path: 'elanlar/xidmetler/dayeler-baxicilar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 30,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '234',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '235',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '236',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NQ',
          name: 'Фото и видеосъёмка',
          path: 'elanlar/xidmetler/foto-ve-video-cekilis',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 26,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '237',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '238',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '239',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3NA',
          name: 'Красота, здоровье',
          path: 'elanlar/xidmetler/gozellik-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 35,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '240',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '241',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '242',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mg',
          name: 'Юридические услуги',
          path: 'elanlar/xidmetler/huquq-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 9,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '243',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '244',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '245',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Mw',
          name: 'IT, интернет, телеком',
          path: 'elanlar/xidmetler/komputer-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 84,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '246',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '247',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '248',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwNg',
          name: 'Логистика',
          path: 'elanlar/xidmetler/logistika',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 160,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '369',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '370',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '371',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OA',
          name: 'Сборка и реставрация мебели',
          path: 'elanlar/xidmetler/sifarisle-mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 103,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '249',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '250',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '251',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Mg',
          name: 'Музыка, развлечения и мероприятия',
          path: 'elanlar/xidmetler/tedbirlerin-teskilati',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 41,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '252',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '253',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '254',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OA',
          name: 'Бухгалтерские услуги',
          path: 'elanlar/xidmetler/muhasibat-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 43,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '255',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '256',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '257',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2OQ',
          name: 'Аренда транспортных средств',
          path: 'elanlar/xidmetler/neqliyyat-icaresi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 300,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '258',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '259',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '260',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Nw',
          name: 'Питание, кейтеринг',
          path: 'elanlar/xidmetler/keyterinq-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 54,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '261',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '262',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '263',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NQ',
          name: 'Реклама, дизайн и полиграфия',
          path: 'elanlar/xidmetler/reklam-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 188,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '264',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '265',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '266',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3Ng',
          name: 'Страхование',
          path: 'elanlar/xidmetler/sigorta-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 6,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '267',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '268',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '269',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MQ',
          name: 'Установка систем безопасности',
          path: 'elanlar/xidmetler/tehlukesizlik-sistemleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 863,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '270',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '271',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '272',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '273',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MA',
          name: 'Обучение, подготовительные курсы',
          path: 'elanlar/xidmetler/telim-hazirliq-kurslari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 251,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '274',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '275',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '276',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Ng',
          name: 'Ремонт и строительство',
          path: 'elanlar/xidmetler/temir-tikinti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 796,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '277',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '278',
              count: 5,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '279',
              count: 10,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '280',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3MA',
          name: 'Уборка',
          path: 'elanlar/xidmetler/temizlik-xidmeti',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 63,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '281',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2NA',
          name: 'Перевод',
          path: 'elanlar/xidmetler/tercume-xidmetleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 20,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '282',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '283',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '284',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2Nw',
          name: 'Ремонт техники',
          path: 'elanlar/xidmetler/texnika-temiri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 319,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '285',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '286',
              count: 3,
              price: 6,
              __typename: 'Bundle',
            },
            {
              id: '287',
              count: 5,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY2MQ',
          name: 'Медицинские услуги',
          path: 'elanlar/xidmetler/tibbi-xidmetler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 33,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '288',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '289',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '290',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1OQ',
          name: 'Другое',
          path: 'elanlar/xidmetler/diger-xidmetler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 99,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Ng',
            name: 'Услуги и бизнес',
            count: 9423,
            path: 'elanlar/xidmetler',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/services-55db5ac3ebd0cb5371bd730a30a90d93ea016b438a143be2a79c2ef058907521.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '291',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '292',
              count: 3,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '293',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
      name: 'Хобби и отдых',
      path: 'elanlar/hobbi-ve-asude',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
      __typename: 'Category',
      metaDescription:
        'Билеты и путешествия, велосипеды, коллекционирование, спорт и отдых, музыкальные инструменты, книги и журналы, знакомства, охота и рыбалка',
      warningMessage: '',
      count: 9048,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMg',
          name: 'Билеты и путешествия',
          path: 'elanlar/hobbi-ve-asude/turlar-ve-biletler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 170,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '2',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '112',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '113',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '114',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyMw',
          name: 'Велосипеды',
          path: 'elanlar/hobbi-ve-asude/velosipedler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 3070,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '115',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '116',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '117',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '118',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNA',
          name: 'Коллекции',
          path: 'elanlar/hobbi-ve-asude/kolleksiyalar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 919,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '119',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '120',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '121',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '122',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNQ',
          name: 'Музыкальные инструменты',
          path: 'elanlar/hobbi-ve-asude/musiqi-aletleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1055,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '123',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '124',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '125',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '126',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNg',
          name: 'Спорт и отдых',
          path: 'elanlar/hobbi-ve-asude/idman-ve-asude',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 2657,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '127',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '128',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '129',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
            {
              id: '130',
              count: 25,
              price: 10,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyNw',
          name: 'Книги и журналы',
          path: 'elanlar/hobbi-ve-asude/kitab-ve-jurnallar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 637,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '131',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '132',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '133',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '134',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOA',
          name: 'Кемпинг, охота и рыбалка',
          path: 'elanlar/hobbi-ve-asude/kempinq-ovculuq-baliqciliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 383,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '135',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '136',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '137',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '138',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNw',
          name: 'Знакомства',
          path: 'elanlar/hobbi-ve-asude/tanisliq',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 157,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYyOQ',
            name: 'Хобби и отдых',
            count: 9048,
            path: 'elanlar/hobbi-ve-asude',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/hobby-leisure-3dc605f529b7237ab7ebe2e2a0302df3b129e36f9752affbcd267507582530c9.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '29',
              count: 1,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
      name: 'Работа',
      path: 'elanlar/is-elanlari',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
      __typename: 'Category',
      metaDescription: 'Вакансии, ищу работу',
      warningMessage: '',
      count: 6508,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OA',
          name: 'Вакансии',
          path: 'elanlar/is-elanlari/vakansiyalar',
          icon: null,
          warningMessage:
            'Мошенники могут потребовать от вас необоснованные платежи (страховка, пошлина и т. д.) под видом трудоустройства!',
          __typename: 'Category',
          count: 5018,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'Работа',
            count: 6508,
            path: 'elanlar/is-elanlari',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '1',
              count: 1,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '163',
              count: 3,
              price: 9,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4OQ',
          name: 'Ищу работу',
          path: 'elanlar/is-elanlari/is-axtariram',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1490,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYzNA',
            name: 'Работа',
            count: 6508,
            path: 'elanlar/is-elanlari',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/work-and-business-61f52b1a769fce45652d6c58dbcfe2d40eb2c64106eaa79e43f0c02bb0a9a19b.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '164',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
      name: 'Животные',
      path: 'elanlar/heyvanlar',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
      __typename: 'Category',
      metaDescription:
        'Собаки, кошки, птицы, аквариум и рыбы, другие животные, товары для животных',
      warningMessage: '',
      count: 6460,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NA',
          name: 'Собаки',
          path: 'elanlar/heyvanlar/itler',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1416,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '139',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '140',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '141',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '142',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5NQ',
          name: 'Кошки',
          path: 'elanlar/heyvanlar/pisikler',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 1218,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '143',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '144',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '145',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '146',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Ng',
          name: 'Птицы',
          path: 'elanlar/heyvanlar/quslar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 2038,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '147',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '148',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '149',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '150',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5Nw',
          name: 'Аквариумы и рыбы',
          path: 'elanlar/heyvanlar/baliqlar-akvariumlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 548,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '151',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '152',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '153',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '154',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5OQ',
          name: 'С/Х животные',
          path: 'elanlar/heyvanlar/kt-heyvanlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 508,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '347',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '348',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '349',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '350',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OA',
          name: 'Другие животные',
          path: 'elanlar/heyvanlar/diger-heyvanlar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 47,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '155',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '156',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '157',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '158',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMA',
          name: 'Кролики',
          path: 'elanlar/heyvanlar/dovsanlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 123,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '351',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '352',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '353',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '354',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzYwMA',
          name: 'Товары для животных',
          path: 'elanlar/heyvanlar/heyvanlar-ucun-mehsullar',
          icon: null,
          warningMessage:
            'Убедитесь в безопасности сделки, перед отправкой предоплаты!',
          __typename: 'Category',
          count: 461,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '159',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '160',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '161',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '162',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMQ',
          name: 'Лошади',
          path: 'elanlar/heyvanlar/atlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 55,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '355',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '356',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '357',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '358',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwMg',
          name: 'Грызуны',
          path: 'elanlar/heyvanlar/gemiriciler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU5OQ',
            name: 'Животные',
            count: 6460,
            path: 'elanlar/heyvanlar',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/animals-b2315f374fddca7f0db70b1fb8e8bc77932968c9bb8b4e9627a4f9ed73eef172.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '359',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '360',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '361',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '362',
              count: 25,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
    {
      id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
      name: 'Детский мир',
      path: 'elanlar/usaqlar-ucun',
      icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
      __typename: 'Category',
      metaDescription:
        'Автокресла, игрушки, детские коляски и автомобили, детская одежда, мебель, питание, для школьников и другое',
      warningMessage: '',
      count: 5337,
      propertyOptions: null,
      parent: {
        id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
        name: 'Все категории',
        path: 'elanlar',
        icon: null,
        __typename: 'Category',
        count: 165339,
      },
      children: [
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OQ',
          name: 'Автокресла',
          path: 'elanlar/usaqlar-ucun/avtomobil-oturacaqlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 146,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '294',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '295',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '296',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '297',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MA',
          name: 'Игрушки',
          path: 'elanlar/usaqlar-ucun/oyuncaqlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 721,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '298',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '299',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '300',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '301',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1MQ',
          name: 'Детские коляски',
          path: 'elanlar/usaqlar-ucun/usaq-arabalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 1057,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '306',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '307',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '308',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY5Nw',
          name: 'Детские автомобили',
          path: 'elanlar/usaqlar-ucun/usaq-avtomobilleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 369,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '309',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '310',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '311',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '312',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mw',
          name: 'Детские кроватки и колыбели',
          path: 'elanlar/usaqlar-ucun/usaq-carpayilari-ve-beshikler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 972,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '313',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '314',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '315',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '316',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Ng',
          name: 'Детские переноски',
          path: 'elanlar/usaqlar-ucun/usaq-dasiyicilari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 73,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '317',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '318',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '319',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mg',
          name: 'Детская одежда',
          path: 'elanlar/usaqlar-ucun/usaq-geyimleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 590,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '320',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '321',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '322',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '323',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1Mw',
          name: 'Детская мебель',
          path: 'elanlar/usaqlar-ucun/mebel',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 257,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '324',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '325',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '326',
              count: 10,
              price: 4,
              __typename: 'Bundle',
            },
            {
              id: '327',
              count: 25,
              price: 8,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NA',
          name: 'Детское питание и кормление',
          path: 'elanlar/usaqlar-ucun/usaq-yemekleri',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 48,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '377',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '378',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '379',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '380',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NQ',
          name: 'Детские горки и игровые площадки',
          path: 'elanlar/usaqlar-ucun/usaq-suruskenleri-ve-oyun-meydancalari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 88,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '328',
              count: 1,
              price: 1,
              __typename: 'Bundle',
            },
            {
              id: '329',
              count: 5,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '330',
              count: 10,
              price: 5,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4Mg',
          name: 'Манежи',
          path: 'elanlar/usaqlar-ucun/manejler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 46,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '331',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '332',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '333',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY3OQ',
          name: 'Для школьников',
          path: 'elanlar/usaqlar-ucun/mekteb-levazimatlari',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 491,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '334',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '335',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '336',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4MA',
          name: 'Xодунки',
          path: 'elanlar/usaqlar-ucun/yurutecler',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 117,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '337',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '338',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '339',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOA',
          name: 'Купание и гигиена',
          path: 'elanlar/usaqlar-ucun/usaq-gigiyenasi',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 54,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '381',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '382',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '383',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzcwOQ',
          name: 'Детский текстиль',
          path: 'elanlar/usaqlar-ucun/usaq-tekstili',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 42,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '384',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '385',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '386',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY4NA',
          name: 'Детское сиденье для кормления',
          path: 'elanlar/usaqlar-ucun/qidalanma-ucun-usaq-oturacaqlar',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 232,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '302',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '303',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '304',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
            {
              id: '305',
              count: 25,
              price: 4,
              __typename: 'Bundle',
            },
          ],
        },
        {
          id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY1NQ',
          name: 'Другое',
          path: 'elanlar/usaqlar-ucun/her-sey',
          icon: null,
          warningMessage: '',
          __typename: 'Category',
          count: 34,
          parent: {
            id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzY0OA',
            name: 'Детский мир',
            count: 5337,
            path: 'elanlar/usaqlar-ucun',
            icon: 'https://tapaz-428644667.azstaging.net/assets/shared/categories-raster/kids-world-9c59af8fe9908badb2d3d9c9540bb186be6e451cdaabee355c733015069c21ec.png',
            __typename: 'Category',
            parent: {
              id: 'Z2lkOi8vdGFwL0NhdGVnb3J5LzU4MQ',
              name: 'Все категории',
              count: 165339,
              path: 'elanlar',
              icon: null,
              __typename: 'Category',
            },
          },
          bundles: [
            {
              id: '340',
              count: 1,
              price: 0.5,
              __typename: 'Bundle',
            },
            {
              id: '341',
              count: 5,
              price: 2,
              __typename: 'Bundle',
            },
            {
              id: '342',
              count: 10,
              price: 3,
              __typename: 'Bundle',
            },
          ],
        },
      ],
      bundles: [],
    },
  ],
};

export const categoriesMock = (locale: string) =>
  locale === 'ru' ? categoriesMockRu : categoriesMockAz;
