import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdOrder } from '@/api/types/ads.types';
import * as S from './FiltersDrawer.styles';

interface CatalogueDrawerProps {
  sort: AdOrder;
  setSort: React.Dispatch<React.SetStateAction<AdOrder>>;
}

export const FiltersDrawerSortItems: React.FC<CatalogueDrawerProps> = ({
  sort,
  setSort,
}) => {
  const { t } = useTranslation();

  const sortItems = Object.values(AdOrder).filter(el => el !== AdOrder.Random);

  return (
    <>
      <S.PropertyName $hasTopGutter>{t('common.sorting')}</S.PropertyName>
      <S.OptionButtonsBox>
        {sortItems.map(el => {
          return (
            <S.OptionButton
              key={el}
              data-testid="sort-item"
              $isActive={sort === el}
              onClick={() => setSort(el)}
              $isLowercase
            >
              {t(`common.${el.toLowerCase()}`)}
            </S.OptionButton>
          );
        })}
      </S.OptionButtonsBox>
    </>
  );
};
