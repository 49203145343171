import { useRouter } from 'next/router';
import { useQuery } from '@apollo/client';
import { GetRegionsResponse } from '@/api/types/regions.types';
import { GET_REGIONS } from '@/api/queries/regions.queries';
import { QUERY } from '@/constants/common';
import { replaceSpaces } from '@/utils/helpers';
import { useMemo } from 'react';
import { Filter } from '@/components/AdsWithInfiniteScroll/useParseQuery.types';
import {
  extractProperties,
  mapQueryOrderToAdOrder,
  returnFirstIfArray,
} from '@/components/AdsWithInfiniteScroll/useParseQuery.helpers';

export function useParseQuery(): Filter {
  const { query } = useRouter();
  const { data: regions } = useQuery<GetRegionsResponse>(GET_REGIONS);

  const regionId =
    regions?.regions.find(
      el => el.legacyResourceId === query[`${QUERY.q}[${QUERY.region_id}]`]
    )?.id || null;
  const regionLegacyId =
    returnFirstIfArray(query[`${QUERY.q}[${QUERY.region_id}]`]) || null;

  const keywords = query[`${QUERY.q}[${QUERY.keywords}]`];
  const keywordsFinal = returnFirstIfArray(keywords);
  const categoryId = query[QUERY.categoryId];
  const categoryIdFinal = returnFirstIfArray(categoryId);

  const priceArr = query[`${QUERY.q}[${QUERY.price}][]`];
  const priceFrom = priceArr ? priceArr[0] : null;
  const priceTo = priceArr ? priceArr[1] : null;

  const allCollections = useMemo(() => extractProperties(query), [query]);

  const [finalPriceFrom, finalPriceTo] = [priceFrom, priceTo].map(el => {
    return el ? Number(replaceSpaces(el)) : null;
  });

  return {
    keywords: keywordsFinal,
    categoryId: categoryIdFinal,
    priceFrom: finalPriceFrom,
    priceTo: finalPriceTo,
    regionId,
    regionLegacyId,
    collectionPropertyOptions: allCollections.collectionPropertyOptions,
    booleanPropertyOptions: allCollections.booleanPropertyOptions,
    rangePropertyOptions: allCollections.rangePropertyOptions,
    order: mapQueryOrderToAdOrder(returnFirstIfArray(query.order)),
  };
}
