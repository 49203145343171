import React, { useState } from 'react';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { useRouter } from 'next/router';

export const useToggle = (
  defaultValue?: boolean | string,
  onToggle?: () => void
): [
  boolean,
  (v?: boolean | React.MouseEvent<HTMLElement>) => void,
  () => void,
  () => void
] => {
  const router = useRouter();
  const { url, hash } = useUrlAndHashFromPath();
  const isBasedOnHash = typeof defaultValue === 'string';
  const [isOpenState, setIsOpenState] = useState(
    isBasedOnHash ? hash === defaultValue : !!defaultValue
  );

  function toggle(v?: boolean | React.MouseEvent<HTMLElement>) {
    onToggle?.();
    if (isBasedOnHash) {
      return router.push(
        hash === defaultValue ? url : `${url}#${defaultValue}`,
        undefined,
        { scroll: false }
      );
    }
    setIsOpenState(prev => (typeof v === 'boolean' ? v : !prev));
  }

  function open() {
    onToggle?.();
    if (isBasedOnHash) {
      return router.push(`${url}#${defaultValue}`, undefined, {
        scroll: false,
      });
    }
    setIsOpenState(true);
  }

  function close() {
    onToggle?.();
    if (isBasedOnHash) {
      return router.push(url, undefined, { scroll: false });
    }
    setIsOpenState(false);
  }

  const isOpen = isBasedOnHash ? hash === defaultValue : isOpenState;

  return [isOpen, toggle, open, close];
};
