import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  fragment BaseCategoryFields on Category {
    id
    name
    count
    path
    icon
  }

  query GetCategories {
    categories {
      ...BaseCategoryFields
      metaDescription
      propertyOptions {
        name
        hasChildren
        legacyResourceId
        parentLegacyId
        propertyLegacyId
      }
      parent {
        ...BaseCategoryFields
      }
      children {
        id
        name
        path
        count
        icon
        propertyOptions {
          name
          hasChildren
          legacyResourceId
          parentLegacyId
          propertyLegacyId
        }
        children {
          id
          name
          path
          count
          icon
          propertyOptions {
            name
            hasChildren
            legacyResourceId
            parentLegacyId
            propertyLegacyId
          }
          parent {
            ...BaseCategoryFields
            parent {
              ...BaseCategoryFields
            }
          }
          bundles {
            id
            count
            price
          }
          warningMessage
          isFreeLimitExceededForUser
        }
        parent {
          ...BaseCategoryFields
          parent {
            ...BaseCategoryFields
          }
        }
        bundles {
          id
          count
          price
        }
        warningMessage
      }
      bundles {
        id
        count
        price
      }
      warningMessage
    }
  }
`;

export const GET_CATEGORY_DETAILS = gql`
  query CategoryDetails($slug: String!) {
    category(slug: $slug) {
      id
      properties {
        id
        name
        type
        parentLegacyId
        popularOptions(parentLegacyId: null) {
          legacyResourceId
          name
          propertyLegacyId
          childrenPropertyIds
        }
        options(parentLegacyId: null) {
          legacyResourceId
          name
          propertyLegacyId
          childrenPropertyIds
        }
        settings {
          from
          step
          to
        }
      }
    }
  }
`;

export const GET_CHILD_PROPERTY_OPTIONS = gql`
  query PropertyOptions($legacyId: ID!, $parentLegacyId: ID) {
    property(legacyId: $legacyId) {
      popularOptions(parentLegacyId: $parentLegacyId) {
        legacyResourceId
        name
        parentLegacyId
        propertyLegacyId
        childrenPropertyIds
      }
      options(parentLegacyId: $parentLegacyId) {
        legacyResourceId
        name
        parentLegacyId
        propertyLegacyId
        childrenPropertyIds
      }
    }
  }
`;
