import React from 'react';
import { useTranslation } from 'react-i18next';
import { BooleanCollection } from '@/components/FiltersDrawer/FiltersDrawer.types';
import { Property } from '@/api/types/categories.types';
import * as S from './FiltersDrawer.styles';

const options = [
  { label: 'common.no_matter', value: undefined },
  { label: 'common.yes', value: true },
  { label: 'common.no', value: false },
];

type Props = {
  property: Property;
  collection: BooleanCollection;
  setCollection: React.Dispatch<React.SetStateAction<BooleanCollection>>;
};

export const BooleanOptions = ({
  property,
  collection,
  setCollection,
}: Props) => {
  const { t } = useTranslation();

  const handleChange = (value: boolean | undefined) => {
    setCollection(prev => ({ ...prev, [property.id]: value }));
  };

  return (
    <>
      <S.PropertyName $hasTopGutter>{property.name}</S.PropertyName>
      <S.OptionButtonsBox>
        {options.map(el => {
          return (
            <S.OptionButton
              key={el.label}
              $isActive={collection[property.id] === el.value}
              onClick={() => handleChange(el.value)}
            >
              {t(el.label)}
            </S.OptionButton>
          );
        })}
      </S.OptionButtonsBox>
    </>
  );
};
