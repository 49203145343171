import { create } from 'zustand';
import { Category } from '@/api/types/categories.types';

interface CategoriesStore {
  currentCategory?: Category;
  setCurrentCategory: (category?: Category) => void;
}

export const useCategories = create<CategoriesStore>(set => ({
  currentCategory: undefined,
  setCurrentCategory: category => set({ currentCategory: category }),
}));
