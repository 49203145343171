import React, { PropsWithChildren } from 'react';
import { Overlay } from '@/components/UI/Overlay/Overlay.styles';
import { CloseIcon } from '@/components/UI/Icons/Icons';
import { Portal } from '@/components/UI/Portal/Portal';
import * as S from './BottomSheet.styles';

interface BottomSheetProps {
  isOpen: boolean;
  close: () => void;

  title?: never;
  isTitleCentered?: never;
  isInPortal?: boolean;
  dataTestId?: string;
}

interface BottomSheetWithTitleProps
  extends Omit<BottomSheetProps, 'title' | 'isTitleCentered'> {
  title: string;

  isTitleCentered?: boolean;
}

export const BottomSheet: React.FC<
  PropsWithChildren<BottomSheetProps | BottomSheetWithTitleProps>
> = ({
  children,
  isOpen,
  close,
  title,
  isTitleCentered = true,
  isInPortal,
  dataTestId,
}) => {
  function renderBottomSheet() {
    return (
      <>
        <Overlay $isOpen={isOpen} onClick={close} />
        <S.Box $isOpen={isOpen} data-testid={dataTestId ?? 'bottom-sheet'}>
          {title && (
            <S.Head $isTitleCentered={isTitleCentered}>
              <S.Title $variant="3">{title}</S.Title>
              <S.Close appearance="transparent" onClick={close}>
                <CloseIcon />
              </S.Close>
            </S.Head>
          )}
          <S.Body>{children}</S.Body>
        </S.Box>
      </>
    );
  }

  if (isInPortal) {
    return <Portal>{renderBottomSheet()}</Portal>;
  }

  return renderBottomSheet();
};
