import { gql } from '@apollo/client';

export const GET_REGIONS = gql`
  query Regions {
    regions {
      id
      legacyResourceId
      name
    }
  }
`;
