import { Category, Property, PropertyType } from '@/api/types/categories.types';
import {
  BooleanCollection,
  Collection,
  GroupedProperties,
  RangeCollection,
} from '@/components/FiltersDrawer/FiltersDrawer.types';

function buildQueryKey(key: string, isRange = false) {
  return isRange ? `p[${key}][]` : `p[${key}]`;
}

function mapBooleanCollectionToQueryString(collection: BooleanCollection) {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(collection)) {
    if (value !== undefined) {
      params.set(buildQueryKey(key), String(value));
    }
  }
  return params;
}

function isCollectionEmpty(
  collection: Collection | BooleanCollection | RangeCollection
) {
  return Object.values(collection).every(el => el === undefined);
}

export function removeKeysOnClear(
  searchParams: URLSearchParams,
  isRangeCollection = false
) {
  const keysToRemove = [];

  const numericKeyPattern = /^p\[\d+]$/;

  const arrayKeyPattern = isRangeCollection ? /^q\[[^\]]+]\[]$/ : null;

  for (const key of searchParams.keys()) {
    if (key === 'q[price][]' || key === 'q[region_id]') {
      continue;
    }
    if (
      numericKeyPattern.test(key) ||
      (arrayKeyPattern && arrayKeyPattern.test(key))
    ) {
      keysToRemove.push(key);
    } else {
      const value = searchParams.get(key);
      if (value === 'true' || value === 'false') {
        keysToRemove.push(key);
      }
    }
  }

  keysToRemove.forEach(key => searchParams.delete(key));
}

function handleRangeCollection(
  searchParams: URLSearchParams,
  collection?: RangeCollection
) {
  if (collection && !isCollectionEmpty(collection)) {
    for (const [key, value] of Object.entries(collection)) {
      if (value !== undefined && Array.isArray(value)) {
        const queryKey = buildQueryKey(key, true);
        searchParams.delete(queryKey);
        value.forEach(val => searchParams.append(queryKey, String(val)));
      }
    }
  } else if (collection) {
    removeKeysOnClear(searchParams, true);
  }
}

function handleBooleanCollection(
  searchParams: URLSearchParams,
  collection?: BooleanCollection
) {
  if (collection && !isCollectionEmpty(collection)) {
    const booleanParams = mapBooleanCollectionToQueryString(collection);
    booleanParams.forEach((value, key) => {
      searchParams.set(key, value);
    });
  } else if (collection) {
    removeKeysOnClear(searchParams);
  }
}

function handleCollection(
  searchParams: URLSearchParams,
  collection?: Collection
) {
  if (collection && !isCollectionEmpty(collection)) {
    for (const [key, value] of Object.entries(collection)) {
      if (value !== undefined) {
        searchParams.append(buildQueryKey(key), value);
      }
    }
  } else if (collection) {
    removeKeysOnClear(searchParams);
  }
}

function modifyUrlPath(urlObj: URL, hasCategory: boolean) {
  try {
    const { pathname } = urlObj;

    if (!hasCategory) {
      // eslint-disable-next-line no-param-reassign
      urlObj.pathname = pathname.replace(/^\/elanlar(\/.*)?$/, '/elanlar');
    }
    return urlObj;
  } catch (error) {
    return urlObj;
  }
}

export function updateURLParameters(
  url: string,
  params: { [key: string]: string | string[] | null | undefined },
  booleanCollection?: BooleanCollection,
  rangeCollection?: RangeCollection,
  collection?: Collection,
  hasCategory = false
): string {
  const updatedURL = new URL(url);
  const searchParams = new URLSearchParams(updatedURL.search);

  ['button', 'mobile_site'].forEach(param => searchParams.delete(param));

  for (const key in params) {
    if (params[key] !== undefined) {
      if (Array.isArray(params[key])) {
        searchParams.delete(key);
        (params[key] as string[]).forEach(value =>
          searchParams.append(key, value)
        );
      } else {
        searchParams.set(key, params[key] as string);
      }
    }
  }

  handleRangeCollection(searchParams, rangeCollection);
  handleBooleanCollection(searchParams, booleanCollection);
  handleCollection(searchParams, collection);

  updatedURL.search = searchParams.toString();
  return modifyUrlPath(updatedURL, hasCategory).toString();
}

export function replaceUrlPath(baseUrl: string, newPath: string) {
  const url = new URL(baseUrl);
  url.pathname = newPath;
  return url.toString();
}

export function getCategorySlug(category?: Category) {
  return category?.path.split('/').at(-1);
}

export function separateBooleanProperties(properties?: Property[]) {
  const initialGroupedProperties = {
    booleanProperties: new Set<Property>(),
    restProperties: new Set<Property>(),
  };

  const groupedProperties =
    properties?.reduce<GroupedProperties>((acc, p) => {
      switch (p.type) {
        case PropertyType.BooleanProperty:
          acc.booleanProperties.add(p);
          break;

        case PropertyType.CollectionProperty:
        case PropertyType.RangeProperty:
          acc.restProperties.add(p);
          break;
        default:
          break;
      }
      return acc;
    }, initialGroupedProperties) ?? initialGroupedProperties;

  return {
    booleanProperties: Array.from(groupedProperties.booleanProperties),
    restProperties: Array.from(groupedProperties.restProperties),
  };
}
