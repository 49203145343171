import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer } from '@/components/Drawer/Drawer';
import { DrawerItems } from '@/components/Drawer/Drawer.styles';
import { Select } from '@/components/UI/Select/Select';
import { Button } from '@/components/UI/Button/Button';
import { Input } from '@/components/UI/Input/Input';
import {
  CatalogueDrawer,
  CatalogueDrawerRef,
} from '@/components/CatalogueDrawer/CatalogueDrawer';
import { CitiesDrawer } from '@/components/CitiesDrawer/CitiesDrawer';
import { useBodyOverflow } from '@/hooks/useBodyOverflow';
import {
  Category,
  GetCategoryDetailsResponse,
  PropertyType,
} from '@/api/types/categories.types';
import { GetRegionsResponse, Region } from '@/api/types/regions.types';
import { useRouter } from 'next/router';
import { AdOrder } from '@/api/types/ads.types';
import { FiltersDrawerSortItems } from '@/components/FiltersDrawer/FiltersDrawerSortItems';
import {
  getCategorySlug,
  replaceUrlPath,
  separateBooleanProperties,
  updateURLParameters,
} from '@/components/FiltersDrawer/FiltersDrawer.helpers';
import { useQuery } from '@apollo/client';
import { GET_CATEGORY_DETAILS } from '@/api/queries/categories.queries';
import { useCategories } from '@/store/useCategories';
import { useUrlAndHashFromPath } from '@/hooks/useUrlAndHashFromPath';
import { BooleanOptions } from '@/components/FiltersDrawer/BooleanOptions';
import * as S from '@/components/FiltersDrawer/FiltersDrawer.styles';
import {
  BooleanCollection,
  CatalogueDrawerProps,
  CategoryData,
  Collection,
  RangeCollection,
} from '@/components/FiltersDrawer/FiltersDrawer.types';
import { useParseQuery } from '@/components/AdsWithInfiniteScroll/useParseQuery';
import { RangeOptions } from '@/components/FiltersDrawer/RangeOptions';
import { GET_REGIONS } from '@/api/queries/regions.queries';
import { CollectionSelect } from '@/components/FiltersDrawer/CollectionSelect';
import { formatNumber } from '@/utils/helpers';
import {
  mapBooleanPropertyOptionsToBooleanCollection,
  mapCollectionPropertyOptionsToCollection,
  mapRangePropertyOptionsToRangeCollection,
} from '@/components/AdsWithInfiniteScroll/useParseQuery.helpers';

export const FiltersDrawer = ({
  isOpen,
  close,
  setFilterCount,
}: CatalogueDrawerProps) => {
  useBodyOverflow(isOpen);

  const router = useRouter();

  const { t } = useTranslation();
  const [prices, setPrices] = useState<{
    from: string | null;
    to: string | null;
  }>({ from: null, to: null });
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const [isCitiesOpen, setIsCitiesOpen] = useState(false);
  const [sort, setSort] = useState<AdOrder>(AdOrder.DateDesc);
  const [city, setCity] = useState<Region | null>(null);
  const [categoryData, setCategoryData] = useState<CategoryData | null>(null);
  const [booleanCollection, setBooleanCollection] = useState<BooleanCollection>(
    {}
  );
  const [rangeCollection, setRangeCollection] = useState<RangeCollection>({});
  const [collection, setCollection] = useState<Collection>({});
  const catalogueDrawerRef = useRef<CatalogueDrawerRef>(null);

  function clearAllCollections() {
    setCollection({});
    setBooleanCollection({});
    setRangeCollection({});
  }

  function resetForm() {
    setCategoryData(null);
    setPrices({ from: null, to: null });
    setCity(null);
    setSort(AdOrder.DateDesc);
    clearAllCollections();
    catalogueDrawerRef.current?.reset();
  }

  const {
    booleanPropertyOptions,
    rangePropertyOptions,
    collectionPropertyOptions,
    priceFrom,
    priceTo,
    regionLegacyId,
    order,
  } = useParseQuery();

  const { data: regions } = useQuery<GetRegionsResponse>(GET_REGIONS);

  const { clearUrl } = useUrlAndHashFromPath();
  const [currentCategory, setCurrentCategory] = useCategories(state => [
    state.currentCategory,
    state.setCurrentCategory,
  ]);

  const slug = getCategorySlug(currentCategory);
  const isFirstLevel = router.query.slug?.length === 1;
  const isSubcategories = router.query.slug?.length === 2;

  const { data: categoryDetails, refetch: refetchCategoryDetails } =
    useQuery<GetCategoryDetailsResponse>(GET_CATEGORY_DETAILS, {
      skip: !slug,
      variables: { slug },
    });

  const { properties } = categoryDetails?.category ?? {};

  const { booleanProperties, restProperties } =
    separateBooleanProperties(properties);

  function toggleCategoriesSubDrawer() {
    setIsCategoriesOpen(prev => !prev);
  }

  function toggleCitiesSubDrawer() {
    setIsCitiesOpen(prev => !prev);
  }

  function onSelectCategory(category: Category, subcategory?: Category) {
    if (!subcategory) return;

    setCategoryData({
      category,
      subcategory,
    });

    clearAllCollections();

    refetchCategoryDetails({
      slug: getCategorySlug(subcategory),
    });
  }

  function applyFilters() {
    const newParams = {
      categoryId: categoryData?.subcategory?.id,
      'q[price][]': [prices.from ?? '', prices.to ?? ''],
      'q[region_id]': city?.legacyResourceId ?? '',
      order: sort.toLowerCase(),
    };

    const newURL = updateURLParameters(
      window.location.href,
      newParams,
      booleanCollection,
      rangeCollection,
      collection,
      !!categoryData
    );

    if (
      !categoryData?.subcategory?.path ||
      clearUrl === categoryData?.subcategory?.path
    ) {
      router.push(newURL);
    } else if (categoryData?.subcategory?.path) {
      setCurrentCategory(categoryData.subcategory);
      router.push(replaceUrlPath(newURL, categoryData.subcategory?.path));
    }
    close();
  }

  function changePrice(e: string, fieldName: keyof typeof prices) {
    setPrices({ ...prices, [fieldName]: e });
  }

  useEffect(() => {
    if (!currentCategory) return;

    if (isFirstLevel) {
      setCategoryData({ category: currentCategory, subcategory: undefined });
    }

    if (isSubcategories && currentCategory.parent) {
      setCategoryData({
        category: currentCategory.parent,
        subcategory: currentCategory,
      });
    }
  }, [currentCategory]);

  useEffect(() => {
    const booleanCollectionFromQuery =
      mapBooleanPropertyOptionsToBooleanCollection(booleanPropertyOptions);
    const rangeCollectionFromQuery =
      mapRangePropertyOptionsToRangeCollection(rangePropertyOptions);
    const collectionFromQuery = mapCollectionPropertyOptionsToCollection(
      collectionPropertyOptions
    );

    setBooleanCollection(booleanCollectionFromQuery);
    setRangeCollection(rangeCollectionFromQuery);
    setCollection(collectionFromQuery);
    setPrices({
      from: priceFrom ? formatNumber(priceFrom) : null,
      to: priceTo ? formatNumber(priceTo) : null,
    });
    setSort(order ?? AdOrder.DateDesc);
  }, []);

  useEffect(() => {
    if (isOpen) {
      const collectionFromQuery = mapCollectionPropertyOptionsToCollection(
        collectionPropertyOptions
      );
      setCollection(collectionFromQuery);
    }
  }, [isOpen]);

  useEffect(() => {
    if (regions) {
      setCity(
        regions.regions.find(r => r.legacyResourceId === regionLegacyId) ?? null
      );
    }
  }, [regions]);

  const filtersCount = useMemo(() => {
    let result = 0;

    const conditions = [
      !!categoryData,
      !!(prices.from || prices.to),
      !!city,
      sort && sort !== AdOrder.DateDesc,
      ...Object.values(collection),
      ...Object.values(booleanCollection).map(el => el !== undefined),
      ...Object.values(rangeCollection).map(
        val => !!(val && (val[0] || val[1]))
      ),
    ];

    result = conditions.reduce(
      (sum, condition) => sum + (condition ? 1 : 0),
      0
    );
    setFilterCount(result);
    return result;
  }, [
    categoryData,
    prices,
    city,
    sort,
    collection,
    booleanCollection,
    rangeCollection,
  ]);

  return (
    <Drawer
      isOpen={isOpen}
      close={close}
      title={t('common.filters')}
      right={{ onClick: resetForm, isDisabled: !filtersCount }}
      isInPortal
    >
      <DrawerItems>
        <Select
          label={t('common.category')}
          toggle={toggleCategoriesSubDrawer}
          value={
            categoryData?.subcategory?.name ||
            categoryData?.category?.name ||
            ''
          }
          iconSrc={
            categoryData?.subcategory
              ? categoryData.category.icon
              : categoryData?.category.icon
          }
          hasIcon
        >
          <CatalogueDrawer
            isOpen={isCategoriesOpen}
            close={toggleCategoriesSubDrawer}
            onSelect={onSelectCategory}
            hasSubcategories
            isInPortal={false}
            ref={catalogueDrawerRef}
          />
        </Select>
      </DrawerItems>

      <DrawerItems $isLast>
        <S.FieldTitle>{t('common.price_azn')}</S.FieldTitle>
        <S.TwoFieldsInRow>
          <Input
            value={prices.from?.toString() ?? ''}
            onChange={value => changePrice(value as string, 'from')}
            clear={() => setPrices({ ...prices, from: null })}
            type="text"
            placeholder={t('common.from')}
            isNumberWithSpaces
          />
          <Input
            value={prices.to?.toString() ?? ''}
            onChange={value => changePrice(value as string, 'to')}
            clear={() => setPrices({ ...prices, to: null })}
            type="text"
            placeholder={t('common.to')}
            isNumberWithSpaces
          />
        </S.TwoFieldsInRow>

        <Select
          label={t('common.city')}
          toggle={toggleCitiesSubDrawer}
          value={city?.name ?? ''}
          onClear={() => setCity(null)}
        >
          <CitiesDrawer
            isOpen={isCitiesOpen}
            close={toggleCitiesSubDrawer}
            selected={city?.name ?? ''}
            onSelect={setCity}
          />
        </Select>
      </DrawerItems>

      {!!restProperties.length && categoryData && (
        <DrawerItems $isLast>
          {restProperties.map(p => {
            switch (p.type) {
              case PropertyType.CollectionProperty:
                return (
                  <CollectionSelect
                    key={p.id}
                    propertyLegacyId={p.id}
                    options={p.options}
                    popularOptions={p.popularOptions}
                    collection={collection}
                    setCollection={setCollection}
                    allProperties={properties}
                    isFiltersOpen={isOpen}
                  />
                );

              case PropertyType.RangeProperty:
                return (
                  <RangeOptions
                    key={p.id}
                    property={p}
                    collection={rangeCollection}
                    setCollection={setRangeCollection}
                  />
                );

              default:
                return null;
            }
          })}
        </DrawerItems>
      )}

      {categoryData &&
        booleanProperties.map(bp => {
          return (
            <BooleanOptions
              key={bp.id}
              property={bp}
              collection={booleanCollection}
              setCollection={setBooleanCollection}
            />
          );
        })}

      <FiltersDrawerSortItems sort={sort} setSort={setSort} />

      <S.FiltersDrawerCTA>
        <Button appearance="primary" isFullWidth onClick={applyFilters}>
          {t('common.show')}
        </Button>
      </S.FiltersDrawerCTA>
    </Drawer>
  );
};
