import { READY_ROUTES_FOR_PRODUCTION, ROUTES } from '@/constants/routes';
import { LinkProps } from 'next/link';
import type { UrlObject } from 'url';
import { startsWithHttp } from '@/utils/helpers';

export function buildURL(data: UrlObject) {
  const baseUrl = window.location.origin;
  const url = new URL(baseUrl + (data.pathname || ''));

  if (data.query) {
    for (const [key, value] of Object.entries(data.query)) {
      if (value != null) {
        url.searchParams.append(key, value.toString());
      }
    }
  }

  if (data.hash) {
    url.hash = data.hash;
  }

  return url.href;
}

type RouteKeys =
  | 'main'
  | 'category'
  | 'subcategory'
  | 'vip'
  | 'new'
  | 'adDetails';

export function matchAdRoute(url: string, keysToCheck: RouteKeys[]) {
  const routes: Record<RouteKeys, RegExp> = {
    main: /^\/elanlar$/,
    new: /^\/elanlar\/new$/,
    vip: /^\/elanlar\/vip$/,
    category: /^\/elanlar\/(?!new$|vip$)[^/]+$/,
    subcategory: /^\/elanlar\/(?!new$|vip$)[^/]+\/[^/]+$/,
    adDetails: /^\/elanlar\/(?!new$|vip$)[^/]+\/[^/]+\/\d+(?:[?#].*)?$/,
  };

  return Object.keys(routes).some(key => {
    const routeKey = key as RouteKeys;
    if (routes[routeKey].test(url)) {
      return keysToCheck.includes(routeKey);
    }
    return false;
  });
}

export function checkRoute(href: LinkProps['href']): {
  isInternal?: boolean;
  hrefString: string;
} {
  const isHrefString = typeof href === 'string';
  let hrefString = isHrefString ? href : href.href || buildURL(href);

  const isInternal = !!READY_ROUTES_FOR_PRODUCTION.find(r => {
    if (r === ROUTES.ad && hrefString.includes(r)) {
      return matchAdRoute(hrefString, [
        'main',
        'category',
        'subcategory',
        'adDetails',
      ]);
    }
    return r === hrefString;
  });

  if (
    !isInternal &&
    !startsWithHttp(hrefString) &&
    typeof window !== 'undefined'
  ) {
    hrefString = `${window.location.origin}${hrefString}`;
  }

  return { isInternal, hrefString };
}
